import React from "react";

// Bootstrap components
import { Container } from "react-bootstrap";

// Custom styles
import "../scss/pages/privacy.styles.scss";

function Privacy() {
  return (
    <section className="privacy">
      <div className="privacy__hero">
        <Container>
          <h3>Aviso de privacidad</h3>
        </Container>
      </div>
      <div className="privacy__content">
        <Container className="py-4">
          <p>
            MOVERS & SHAKERS, S.A. DE C.V. o Güeya, con domicilio en Av. de los
            Maestros #4615 Fracc Olinda , Aguascalientes, Aguascalientes, México
            C.P. 20200, y con dirección web{" "}
            <a href="https:// www.gueya.io">https:// www.gueya.io</a> , es el
            responsable del uso y protección de sus datos personales, y al
            respecto le informamos lo siguiente:
          </p>
          <h6>¿Para qué fines utilizaremos sus datos personales?</h6>
          <ul className="mt-2">
            <li>Creación de perfil y cuenta.</li>
            <li>Seguimiento de servicios.</li>
            <li>Notificaciones de cambios.</li>
            <li>Evaluación de servicio. </li>
            <li> Soporte técnico.</li>
            <li>Seguimiento de compra fallida.</li>
            <li>Procesamiento de pago.</li>
            <li>Mercadotecnia o publicitaria. </li>
            <li>Prospección comercial.</li>
          </ul>
          <p>
            De manera adicional, utilizaremos su información personal para las
            siguientes finalidades secundarias que no son necesarias para el
            servicio solicitado, pero que nos permiten y facilitan brindarle una
            mejor atención:
          </p>
          <h6>
            Notificación de productos o servicios de acuerdo a sus intereses.
          </h6>
          <p>
            En caso de que no desee que sus datos personales sean tratados para
            estos fines secundarios, desde este momento usted nos puede
            comunicar lo anterior a través del siguiente mecanismo: Enviando un
            correo electrónico a{" "}
            <a href="mailto:contacto@gueya.io">contacto@gueya.io</a>{" "}
            manifestando su negativa y acompañado de su identificación oficial y
            medios de contacto.
          </p>
          <p>
            La negativa para el uso de sus datos personales para estas
            finalidades no podrá ser un motivo para que le neguemos los
            servicios y productos que solicita o contrata con nosotros.
          </p>
          <h6>¿Qué datos personales utilizaremos para estos fines?</h6>
          <p>
            Para llevar a cabo las finalidades descritas en el presente aviso de
            privacidad, utilizaremos los siguientes datos personales:
          </p>
          <ul>
            <li>Nombre</li>
            <li>Registro Federal de Contribuyentes (RFC).</li>
            <li>Fecha de nacimiento.</li>
            <li>Domicilio.</li>
            <li>Teléfono celular.</li>
            <li>Nombre de Usuario Correo electrónico.</li>
            <li>Edad.</li>
            <li>Puesto o cargo que desempeña.</li>
            <li>Domicilio de trabajo.</li>
            <li>Correo electrónico institucional.</li>
            <li>Teléfono institucional.</li>
            <li>Cuentas bancarias.</li>
            <li>Pasatiempos.</li>
            <li>Aficiones.</li>
            <li>Intereses.</li>
          </ul>
          <p>
            Es probable que durante su uso de la plataforma se le solicite un
            dato distinto de los antes mencionados, ese dato no es necesario
            para las funciones primarias de Güeya y no es solicitado por la
            misma.
          </p>
          <h6>
            ¿Cómo puede acceder, rectificar o cancelar sus datos personales, u
            oponerse a su uso?
          </h6>
          <p>
            Usted tiene derecho a conocer qué datos personales tenemos de usted,
            para qué los utilizamos y las condiciones del uso que les damos
            (Acceso). Asimismo, es su derecho solicitar la corrección de su
            información personal en caso de que esté desactualizada, sea
            inexacta o incompleta (Rectificación); que la eliminemos de nuestros
            registros o bases de datos cuando considere que la misma no está
            siendo utilizada adecuadamente (Cancelación); así como oponerse al
            uso de sus datos personales para fines específicos (Oposición).
            Estos derechos se conocen como derechos ARCO.
          </p>
          <p>
            Para el ejercicio de cualquiera de los derechos ARCO, usted deberá
            presentar la solicitud respectiva a través del siguiente medio:
          </p>
          <p>
            Enviar su solicitud por correo electrónico a{" "}
            <a href="mailto:contacto@gueya.io">contacto@gueya.io</a> o
            personalmente en nuestras oficinas.{" "}
          </p>
          <p>
            En caso de ser representante del titular también deberá adjuntar
            alguno de los siguientes:
          </p>
          <ul>
            <li>Instrumento Público.</li>
            <li>Carta Poder.</li>
            <li>
              También puede el titular, comparecer personalmente a Güeya y
              declarar su voluntad de ser representado.
            </li>
          </ul>
          <p>
            Con relación al procedimiento y requisitos para el ejercicio de sus
            derechos ARCO, le informamos lo siguiente:
          </p>
          <p>
            ¿A través de qué medios pueden acreditar su identidad el titular y,
            en su caso, su representante, así como la personalidad este último?
            Por correo electrónico a{" "}
            <a href="mailto:contacto@gueya.com">contacto@gueya.com</a> o
            personalmente en nuestras oficinas.
          </p>
          <p>
            ¿Qué información y/o documentación deberá contener la solicitud? La
            contenida en el formulario de apoyo para la solicitud de derechos
            arco.
          </p>
          <p>¿En cuántos días le daremos respuesta a su solicitud? 20</p>
          <p>
            ¿Por qué medio le comunicaremos la respuesta a su solicitud? Por
            correo electrónico
          </p>
          <p>
            ¿En qué medios se pueden reproducir los datos personales que, en su
            caso, solicite? Disco compacto, memoria flash, etc.
          </p>
          <p>
            Los datos de contacto de la persona o departamento de datos
            personales, que está a cargo de dar trámite a las solicitudes de
            derechos ARCO, son los siguientes:
          </p>
          <p>
            <span>Güeya</span>
          </p>
          <p>Departamento de Datos personales</p>
          <p>
            Domicilio: Av de los Maestros #4615 Fracc Olinda , Aguascalientes,
            Aguascalientes, México C.P. 20200.
          </p>
          <p>
            Correo electrónico:{" "}
            <a href="mailto:contacto@gueya.io">contacto@gueya.io</a>
          </p>
          <p>
            Número telefónico: <a href="tel:+524492990792">(449) 299 0792</a>
          </p>
          <p>
            Usted puede revocar su consentimiento para el uso de sus datos
            personales.
          </p>
          <p>
            Usted puede revocar el consentimiento que, en su caso, nos haya
            otorgado para el tratamiento de sus datos personales. Sin embargo,
            es importante que tenga en cuenta que no en todos los casos podremos
            atender su solicitud o concluir el uso de forma inmediata, ya que es
            posible que por alguna obligación legal requiramos seguir tratando
            sus datos personales. Asimismo, usted deberá considerar que, para
            ciertos fines, la revocación de su consentimiento implicará que no
            le podamos seguir prestando el servicio que nos solicitó, o la
            conclusión de su relación con nosotros.
          </p>
          <p>
            Para revocar su consentimiento deberá presentar su solicitud a
            través del siguiente medio: Personalmente o por correo electrónico a{" "}
            <a href="mailto:contacto@gueya.io">contacto@gueya.io</a>,
            acreditando su personalidad y/o representación legal.
          </p>
          <p>
            Con relación al procedimiento y requisitos para la revocación de su
            consentimiento, le informamos lo siguiente:
          </p>
          <p>
            ¿A través de qué medios pueden acreditar su identidad el titular y,
            en su caso, su representante, así como la personalidad este último?
            Por correo electrónico o personalmente. Acreditando identidad
            mediante identificación oficial, la representación mediante
            instrumento público, carta poder o declaración del titular en
            comparecencia con nosotros
          </p>
          <p>
            ¿Qué información y/o documentación deberá contener la solicitud?
            Identificación oficial, correo electrónico, otros datos de contacto,
            cualquier información que facilite la ubicación de los datos.
          </p>
          <p>¿En cuántos días le daremos respuesta a su solicitud? 20</p>
          <p>
            ¿Por qué medio le comunicaremos la respuesta a su solicitud? Por
            correo electrónico
          </p>
          <p>
            ¿Cómo puede limitar el uso o divulgación de su información personal?
            Con objeto de que usted pueda limitar el uso y divulgación de su
            información personal, le ofrecemos los siguientes medios: Enviar un
            correo electrónico a{" "}
            <a href="mailto:contacto@gueya.io">contacto@gueya.io</a>
          </p>
          <p>
            El uso de tecnologías de rastreo en nuestro portal de internet Le
            informamos que en nuestra página de internet utilizamos cookies, web
            beacons u otras tecnologías, a través de las cuales es posible
            monitorear su comportamiento como usuario de internet, así como
            brindarle un mejor servicio y experiencia al navegar en nuestra
            página. Los datos personales que recabamos a través de estas
            tecnologías, los utilizaremos para los siguientes fines: Para el
            cumplimiento de los servicios de www.gueya.io Los datos personales
            que obtenemos de estas tecnologías de rastreo son los siguientes:
          </p>
          <ul>
            <li>
              Identificadores, nombre de usuario y contraseñas de una sesión
            </li>
            <li>Idioma preferido por el usuario</li>
            <li>Región en la que se encuentra el usuario</li>
            <li>Fecha y hora del inicio y final de una sesión de un usuario</li>
            <li>Páginas web visitadas por un usuario</li>
            <li>Búsquedas realizadas por un usuario</li>
          </ul>
          <p>
            Asimismo, le informamos que su información personal será compartida
            con las siguientes personas, empresas, organizaciones o autoridades
            distintas a nosotros, para los siguientes fines:
          </p>
          <ul>
            <li>Destinatario de los datos personales</li>
            <li>Finalidad</li>
            <li>Al organizador del evento</li>
            <li>Para el cumplimiento final de los servicios de Güeya</li>
            <li>Esquema de seguridad</li>
          </ul>
          <p>
            Güeya utiliza la infraestructura de hosting proveida por Amazon Web
            Services el cual cuenta con:
          </p>
          <ul>
            <li>Esquemas de Seguridad de SOC 1 tipo ll, SOC 2 tipo ll.</li>
            <li>Encripción SSL/TLS</li>
            <li>Auditoría de archivos y Sistemas de Detección de Intrusos</li>
          </ul>
          <p>
            El alojamiento de la información de usuarios, datos de eventos y
            respaldo de los mismo son llevadas acabo por la infraestructura y
            tecnología proporcionada por Amazon Web Services.
          </p>
          <p>
            La plataforma cuenta con un certificado SSL por lo que la
            información viaja cifrada. Las contraseñas de usuarios son cifradas
            con método de una sola vía, acceso a datos de asistentes y
            prospectos sólo disponible para usuarios autenticados
            (administrador, organizador y vendedor).
          </p>
          <p>
            El servidor web mantiene comunicación a través de red local con el
            servidor de base de datos a través de su ip local. El servidor de
            base de datos tiene deshabitado el acceso remoto por seguridad
            (acceso sólo por VPN). Configuración de fail2ban como medida
            adicional de seguridad anti fuerza bruta.
          </p>
          <p>Tokenización de pasarelas de pago</p>
          <p>
            Tokenización de pasarelas de pago Güeya utiliza pasarelas de pago
            para el manejo de información sensible, por lo que ninguna
            información de tarjetas es guardada en nuestro servidor. Estos
            proveedores cumplen con los siguientes estándares y certificaciones:
          </p>
          <ul>
            <li>ISO 8583</li>
            <li>ISO 27017</li>
            <li>ISO 270018</li>
            <li>PCI DSS Nivel 1</li>
          </ul>
          <h6>¿Cómo puede conocer los cambios en este aviso de privacidad?</h6>
          <p>
            El presente aviso de privacidad puede sufrir modificaciones, cambios
            o actualizaciones derivadas de nuevos requerimientos legales; de
            nuestras propias necesidades por los productos o servicios que
            ofrecemos; de nuestras prácticas de privacidad; de cambios en
            nuestro modelo de negocio, o por otras causas. Nos comprometemos a
            mantenerlo informado sobre los cambios que pueda sufrir el presente
            aviso de privacidad, a través de: Publicación en el sitio web. El
            procedimiento a través del cual se llevarán a cabo las
            notificaciones sobre cambios o actualizaciones al presente aviso de
            privacidad es el siguiente: Anuncio directo en el sitio web.
          </p>

          <p>Última actualización: 20/11/2022</p>
        </Container>
      </div>
    </section>
  );
}

export default Privacy;
