import { useEffect, useContext, useState } from "react";
import { useParams } from "react-router";
import { Link, useHistory } from "react-router-dom";

// Bootstrap components
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

// API
import {
  postAvatarProfile,
  postWWWAvatarProfile,
  updateProfile,
  updateWWWProfile,
  getWWWProfile,
  getProfile,
} from "../api/auth";
import {
  getFacebookProfile,
  getWWWFacebookProfile,
  postInstagramProfile,
  getInstagramProfile,
  getWWWInstagramProfile,
} from "../api/social";

// Context
import AlertContext from "../contexts/AlertContext";
import { useAuth } from "../contexts/AuthContext";

// Custom styles
import "../scss/pages/register.styles.scss";
import "../scss/pages/social.styles.scss";

// Assets
import logo from "../assets/img/logo-gueya.png";

// Formik for form validation
import { Formik } from "formik";
import * as yup from "yup";

const initialValues = {
  instagramUsername: "",
  lastname: "",
  phone: "",
  email: "",
  emailConfirmation: "",
  username: "",
  password: "",
  passwordConfirmation: "",
};

const schema = yup.object().shape({
  instagramUsername: yup.string().required("Este campo es requerido"),

  // terms: yup
  //   .bool()
  //   .required()
  //   .oneOf(
  //     [true],
  //     "Los términos y condiciones deben ser aceptados para continuar"
  //   ),
});

function Register() {
  let { link } = useParams();
  const history = useHistory();
  const alert = useContext(AlertContext);

  const [loading, setLoading] = useState(false);
  // const [isFileMaxSize, setIsFileMaxSize] = useState(false);
  const [profileExistsLoading, setProfileExistsLoading] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  const [file, setFile] = useState();

  const { registerUser, currentUser, registerWWWUser } = useAuth();

  useEffect(() => {
    if (currentUser.user_id) {
      history.push("/");
      // console.log("no hay usuario");
    }
  }, [currentUser, link]);

  useEffect(() => {
    (async () => {
      setProfileExistsLoading(true);
      const currentUrl = window.location.href;
      if (currentUrl.includes("www")) {
        const fetch = await getWWWInstagramProfile(JSON.stringify(link)).then(
          (res) => {
            if (res.error === true) {
              setProfileExistsLoading(false);
            } else if (res.error === false) {
              window.location.replace(
                `https://www.instagram.com/${res.profile.ig_profile}`
              );
              // window.alert("Si existe");
              setIsProfile(true);
              // console.log(res.profile);
              setProfileExistsLoading(false);
            } else {
              setProfileExistsLoading(false);
            }
            setLoading(false);
          }
        );
      } else {
        const fetch = await getInstagramProfile(JSON.stringify(link)).then(
          (res) => {
            if (res.error === true) {
              setProfileExistsLoading(false);
            } else if (res.error === false) {
              window.location.replace(
                `https://www.instagram.com/${res.profile.ig_profile}`
              );
              // console.log("exists!");
              // window.alert("Si existe");
              setIsProfile(true);
              // console.log(res.profile);
              setProfileExistsLoading(false);
            } else {
              setProfileExistsLoading(false);
            }
            setLoading(false);
          }
        );
      }
    })();
  }, [link]);

  if (profileExistsLoading || isProfile) {
    return (
      <section className="register__wrapper register__wrapper--reviews">
        <div className="register__container">
          <div className="container text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Cargando...</span>
            </Spinner>
          </div>
        </div>{" "}
      </section>
    );
  }

  return (
    <section className="register__wrapper register__wrapper--reviews">
      <div className="register__container">
        <img src={logo} alt="Güeya" className="register__logo" />
        <h3>Configuración de cuenta con Instagram</h3>
        <p className="mt-2">Ingresa la información solicitada:</p>
        <Formik
          validationSchema={schema}
          initialValues={initialValues}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setLoading(true);
            values.link = link;
            // const profileUrl = `https://www.facebook.com/${values.instagramUsername}/reviews`;
            // console.log(profileUrl);
            // console.log(values);
            // window.location.replace(
            //   `https://www.facebook.com/${values.facebookUsername}/reviews`
            // );
            const currentUrl = window.location.href;
            const response = await postInstagramProfile(values).then(
              async (res) => {
                console.log(res.error);
                // -- VALIDATION IF IT EXISTS --
                if (res.error === true) {
                  alert.error("Hubo un error al guardar tus datos");
                  setLoading(false);
                  return;
                } else {
                  alert.success("Registro exitoso.");
                  setLoading(false);
                  window.location.replace(
                    `https://www.instagram.com/${values.instagramUsername}`
                  );
                  console.log("success");
                }
              }
            );
            // if (currentUrl.includes("www")) {
            //   const response = await registerWWWUser(values).then(
            //     async (res) => {
            //       // console.log(res);
            //       if (
            //         res.error === true &&
            //         res.message === "Username or Email already exists"
            //       ) {
            //         alert.error("El nombre de usuario o email ya existen.");
            //         setLoading(false);
            //         return;
            //       } else if (
            //         res.error === false &&
            //         res.message ===
            //           "Your registration was completed successfully"
            //       ) {
            //         setImageUploadLoading(true);
            //         const responseAvatar = await postWWWAvatarProfile(
            //           values.avatar
            //         ).then(async (r) => {
            //           // console.log(r, "r");
            //           // values.lastname = values.lastname;
            //           values.id_user = res.user_id;
            //           values.imgProfileId = r;
            //           values.businessName = "";
            //           values.businessRole = "";
            //           values.businessWebsite = "";
            //           values.businessPhone = "";
            //           values.businessExt = "";
            //           values.businessAddress = "";
            //           values.businessNeighborhood = "";
            //           values.state = "";
            //           values.postalCode = "";
            //           values.country = "";
            //           values.tikTokLink = "";
            //           values.instagramLink = "";
            //           values.facebookLink = "";
            //           values.linkedInLink = "";
            //           const response = await updateWWWProfile(values);
            //           if (response.error) {
            //             setImageUploadLoading(false);
            //             alert.error(
            //               "Hubo un error en tu solicitud. Por favor intenta de nuevo más tarde."
            //             );
            //             setLoading(false);
            //             setImageUploadLoading(false);
            //           } else {
            //             // setImageUploadLoading(false);
            //             // alert.success(
            //             //   "Perfil Actualizado"
            //             // );
            //             // setUpdateLoading(false);
            //             // window.scrollTo(0, 0);
            //             alert.success("Registro exitoso.");
            //             history.push("/log-in");
            //             setLoading(false);
            //             setImageUploadLoading(false);
            //           }
            //         });
            //         return;
            //       } else {
            //         alert.error(
            //           "Error en tu solicitud. Por favor intenta de nuevo más tarde"
            //         );
            //         setLoading(false);
            //         return;
            //       }
            //     }
            //   );
            // } else {
            //   const response = await registerUser(values).then(async (res) => {
            //     if (
            //       res.error === true &&
            //       res.message === "Username or Email already exists"
            //     ) {
            //       alert.error("El nombre de usuario o email ya existen.");
            //       return;
            //     } else if (
            //       res.error === false &&
            //       res.message === "Your registration was completed successfully"
            //     ) {
            //       setImageUploadLoading(true);
            //       const responseAvatar = await postAvatarProfile(
            //         values.avatar
            //       ).then(async (r) => {
            //         if (typeof r == "number") {
            //           // console.log(r, "r");
            //           // values.lastname = values.lastname;
            //           values.id_user = res.user_id;
            //           values.imgProfileId = r;
            //           values.businessName = "";
            //           values.businessRole = "";
            //           values.businessWebsite = "";
            //           values.businessPhone = "";
            //           values.businessExt = "";
            //           values.businessAddress = "";
            //           values.businessNeighborhood = "";
            //           values.state = "";
            //           values.postalCode = "";
            //           values.country = "";
            //           values.tikTokLink = "";
            //           values.instagramLink = "";
            //           values.facebookLink = "";
            //           values.linkedInLink = "";
            //           const response = await updateProfile(values);
            //           if (response.error) {
            //             setImageUploadLoading(false);
            //             alert.error(
            //               "Hubo un error en tu solicitud. Por favor intenta de nuevo más tarde."
            //             );
            //             setLoading(false);
            //             // setImageUploadLoading(false);
            //           } else {
            //             // setImageUploadLoading(false);
            //             // alert.success(
            //             //   "Perfil Actualizado"
            //             // );
            //             // setUpdateLoading(false);
            //             // window.scrollTo(0, 0);
            //             alert.success("Registro exitoso.");
            //             history.push("/log-in");
            //             setLoading(false);
            //             setImageUploadLoading(false);
            //           }
            //         } else {
            //           alert.error(
            //             "El tamaño de la imagen seleccionada supera los 2.5Mb permitidos."
            //           );
            //           setImageUploadLoading(false);
            //           setLoading(false);
            //         }
            //       });
            //       return;
            //     } else {
            //       alert.error(
            //         "Error en tu solicitud. Por favor intenta de nuevo más tarde"
            //       );
            //       setImageUploadLoading(false);
            //       setLoading(false);
            //       return;
            //     }
            //   });
            // }
            // if (response.jwt) {
            //   alert.success(
            //     `¡Registro exitoso! Por favor, inicia sesión con tus datos.`
            //   );
            //   history.push("/login");
            //   resetForm(initialValues);
            // } else {
            //   alert.error("Error al registrar usuario.");
            //   setLoading(false);
            //   resetForm(initialValues);
            // }
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group controlId="formRegisterName" className="mb-3">
                <Form.Label>Nombre de usuario de instagram:*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nombre de usuario"
                  name="instagramUsername"
                  value={values.instagramUsername}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={
                    !!errors.instagramUsername && touched.instagramUsername
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors.instagramUsername || touched.instagramUsername}
                </Form.Control.Feedback>
              </Form.Group>
              <a
                href="https://www.facebook.com/help/instagram/583107688369069?cms_platform=iphone-app&helpref=platform_switcher"
                target="_blank"
                rel="noreferrer"
                className="d-block"
              >
                ¿Cómo encontrar tu nombre de usuario en Instagram?{" "}
              </a>
              {/* <Form.Group className="mb-3">
                <Form.Check type="checkbox">
                  <Form.Check.Input
                    type="checkbox"
                    isValid={false}
                    name="terms"
                    onChange={handleChange}
                    isInvalid={!!errors.terms && touched.terms}
                    feedback={errors.terms}
                    // feedbackType="invalid"
                  />
                  <Form.Check.Label>
                    Acepta las <Link to="/policy">políticas de servicio</Link>
                  </Form.Check.Label>
                  <Form.Control.Feedback type="invalid">
                    {errors.terms}
                  </Form.Control.Feedback>
                </Form.Check>
              </Form.Group> */}
              <button
                type="submit"
                disabled={loading || values.instagramUsername === ""}
              >
                {loading ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Cargando...</span>
                  </Spinner>
                ) : values.instagramUsername === "" ? (
                  "Ingresa tu información"
                ) : (
                  "Guardar información"
                )}
                {/* Registrarme ahora */}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
}

export default Register;
