// Custom styles
import "../scss/pages/home.styles.scss";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import Carousel from "react-bootstrap/Carousel";

// Assets
import logo from "../assets/img/logo-gueya.png";
import figure from "../assets/img/figure.svg";
import tarjetaGueya from "../assets/img/tarjeta-1_gueya.png";
import tarjeta2Gueya from "../assets/img/tarjeta-2_gueya.png";
import stickerGueya from "../assets/img/sticker_gueya.png";
import tarjetaGueya2 from "../assets/img/tarjeta_gueya.png";
import howItWorks from "../assets/img/funcion_gueya.gif";
import benefits from "../assets/img/beneficios_gueya2.jpg";
import benefitsResponsive from "../assets/img/c-responsive.jpg";
import celGueya from "../assets/img/cel_gueya.jpg";
import arrowDownIcon from "../assets/img/arrow-down.svg";
import MPLogo from "../assets/img/logotipo_MP.png";
import MrLuckyLogo from "../assets/img/mr-lucky.png";
import GNPLogo from "../assets/img/logo-GNP.png";
import DHLogo from "../assets/img/dog-house.png";

function Home() {
  return (
    <section className="home">
      <div className="about-us__content pb-0">
        {/* <Carousel>
          <Carousel.Item className="carousel-item--1">
            <Carousel.Caption>
              <h1>Donde quiera que vayas, siempre deja Güeya</h1>
              <p>La primera impresión, lo es todo.</p>
              <a href="https://tarjeta-gueya.myshopify.com/products/tarjeta-gueya">
                ¡La quiero!
              </a>
            </Carousel.Caption>
            <div className="home__hero__slide">
              <div>
                <img src={arrowDownIcon} alt="Güeya" />
              </div>
              <p>Desliza hacia abajo</p>
            </div>
          </Carousel.Item>
          <Carousel.Item className="carousel-item--2">
            <Carousel.Caption>
              <h1>Donde quiera que vayas, siempre deja Güeya</h1>
              <p>La primera impresión, lo es todo.</p>
              <a href="https://tarjeta-gueya.myshopify.com/products/tarjeta-gueya">
                ¡La quiero!
              </a>
            </Carousel.Caption>
            <div className="home__hero__slide">
              <div>
                <img src={arrowDownIcon} alt="Güeya" />
              </div>
              <p>Desliza hacia abajo</p>
            </div>
          </Carousel.Item>
          <Carousel.Item className="carousel-item--3">
            <Carousel.Caption>
              <h1>Donde quiera que vayas, siempre deja Güeya</h1>
              <p>La primera impresión, lo es todo.</p>
              <a href="https://tarjeta-gueya.myshopify.com/products/tarjeta-gueya">
                ¡La quiero!
              </a>
            </Carousel.Caption>
            <div className="home__hero__slide">
              <div>
                <img src={arrowDownIcon} alt="Güeya" />
              </div>
              <p>Desliza hacia abajo</p>
            </div>
          </Carousel.Item>
        </Carousel> */}
        <Container className="about-us__content__get">
          <Row>
            <Col xs="12" lg="7" className="d-flex align-items-end">
              <img src={tarjetaGueya2} alt="Güeya" className="img-fluid" />
            </Col>
            <Col
              xs="12"
              lg="5"
              className="d-flex flex-column justify-content-center align-items-start"
            >
              <h1>
                Güeya es una tarjeta de presentación digital con tecnología
                contactless (NFC) muy fácil de usar.
              </h1>
              <p>Desde $490.00 MXN</p>
              <a href="https://tarjeta-gueya.myshopify.com">
                {/* <a href="https://tarjeta-guey⁄a.myshopify.com/products/tarjeta-gueya"> */}
                Comprar ahora
              </a>
            </Col>
          </Row>
        </Container>
      </div>
      {/* <div className="home__hero">
        <div className="home__hero__wrapper">
          <h1>Donde quiera que vayas, siempre deja</h1>
          <p>La primera impresión, lo es todo.</p>
          <a href="">¡La quiero!</a>
        </div>
        <div className="home__hero__slide">
          <div>
            <img src={arrowDownIcon} alt="Güeya" />
          </div>
          <p>Desliza hacia abajo</p>
        </div>
      </div> */}
      <div id="about" className="about-us">
        <div className="about-us__header">
          <img src={figure} alt="Güeya" />
          <div>
            <h2>Una nueva forma de conectar fácil y rápido.</h2>
            <p>
              Llega a todas las agendas con{" "}
              <span>tu tarjeta de presentación digital Güeya.</span>
            </p>
          </div>
        </div>
        <div className="about-us__media">
          <img src={tarjetaGueya} alt="Güeya" />
          <img src={stickerGueya} alt="Güeya" />
          <img src={tarjeta2Gueya} alt="Güeya" />
        </div>
        <div className="about-us__content">
          <Container>
            <p>
              <span>
                Güeya es una tarjeta de presentación digital muy fácil de usar.
              </span>
            </p>
            <p>
              Simplemente acerca tu Güeya a un teléfono celular o escanea el
              código QR e inmediatamente tus datos de contacto aparecerán en
              pantalla. Con solo presionar un botón, tu información quedará
              guardada en el directorio celular y listo.
            </p>
            <a href="https://tarjeta-gueya.myshopify.com">
              {/* <a href="https://tarjeta-gueya.myshopify.com/products/tarjeta-gueya"> */}
              Quiero mi Güeya
            </a>
          </Container>
          {/* <Container className="about-us__content__get">
            <Row>
              <Col xs="12" lg="7">
                <img src={tarjetaGueya2} alt="Güeya" className="img-fluid" />
              </Col>
              <Col
                xs="12"
                lg="5"
                className="d-flex flex-column justify-content-center align-items-start"
              >
                <h2>
                  Consigue tu Güeya por solo $350.00 <span>MXN</span>
                </h2>
                <a href="https://tarjeta-gueya.myshopify.com/products/tarjeta-gueya">
                  Comprar ahora
                </a>
              </Col>
            </Row>
          </Container> */}
        </div>
        <p className="about-us__float">Una Güeya, miles de contactos.</p>
      </div>
      <div id="how-it-works" className="how-it-works">
        <Container fluid>
          <Row>
            <Col xs="12" lg="6">
              <div className="how-it-works__content">
                <h2>¿Cómo funciona?</h2>
                <Row>
                  <Col xs="12" lg="6" className="mt-3 mt-lg-0">
                    <h4>1. Vincula</h4>
                    <p>
                      Presenta tu tarjeta de presentación digital Güeya y
                      escanea el QR, llena el formulario de registro.
                    </p>
                  </Col>
                  <Col xs="12" lg="6" className="mt-4 mt-lg-5">
                    <h4>2. Configura</h4>
                    <p>
                      Personaliza los datos que quieras compartir con tus
                      contactos.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" lg="7" className="mx-auto mt-4">
                    <h4>3. Conecta</h4>
                    <p>
                      Acerca tu Güeya al celular de tu prospecto (si es iPhone
                      por la parte delantera y si es Android por la parte de
                      atrás) y de inmediato tu perfil aparecerá en su pantalla
                    </p>
                    <a href="https://tarjeta-gueya.myshopify.com">
                      {/* <a href="https://tarjeta-gueya.myshopify.com/products/tarjeta-gueya"> */}
                      ¡La quiero!
                    </a>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs="12" lg="6">
              <img src={howItWorks} alt="Güeya" />
            </Col>
          </Row>
        </Container>
      </div>
      <div id="benefits" className="benefits">
        <Container>
          <h2>¿Qué beneficios te da usar Güeya?</h2>
          <p>Conecta de inmediato.</p>
        </Container>
        <img
          src={benefits}
          alt="Güeya"
          className="img-fluid d-none d-lg-block mx-auto"
        />
        <img
          src={benefitsResponsive}
          alt="Güeya"
          className="img-fluid d-lg-none"
        />
      </div>
      <div className="benefits-cta">
        <Container>
          <Row>
            <Col xs="12" lg="4" className="d-flex align-items-center ">
              <p>Sin tener que descargar una APP.</p>
            </Col>
            <Col xs="12" lg="8">
              <h3>
                Podrás usar tu tarjeta de presentación digital Güeya
                ilimitadamente desde solo $490.00 MXN.
              </h3>
              <a href="https://tarjeta-gueya.myshopify.com">
                {/* <a href="https://tarjeta-gueya.myshopify.com/products/tarjeta-gueya"> */}
                Quiero mi Güeya
              </a>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="q-a">
        <div className="q-a__media">
          <img src={celGueya} alt="Güeya" className="img-fluid" />
        </div>
        <div className="q-a__content">
          <Container>
            <h3>Preguntas frecuentes.</h3>
            <Accordion defaultActiveKey="0" flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>¿Cómo funciona?</Accordion.Header>
                <Accordion.Body>
                  Simplemente acerca tu tarjeta de presentación digital Güeya a
                  un teléfono celular o escanea el código QR e inmediatamente
                  tus datos de contacto aparecerán en la pantalla. Con solo
                  presionar un botón, tu información quedará guardada en el
                  directorio celular y ¡listo!
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  ¿Cómo configurar mi información?
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>
                      Escanea el QR que aparece en tu tarjeta tarjeta de
                      presentación digital y abre el formulario de registro.
                    </li>
                    <li>
                      Personaliza los datos que quieras compartir con tus
                      contactos.
                    </li>
                    <li>
                      ¡Conecta! Acerca tu Güeya al celular de tu prospecto (si
                      es iPhone por la parte delantera y si es android por la
                      parte de atrás) y de inmediato tu perfil aparecerá en su
                      pantalla
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  ¿Cuánto tiempo lleva el envío?
                </Accordion.Header>
                <Accordion.Body>
                  <p> 3-5 dias a partir de la fecha de tu compra.</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>¿Qué beneficios obtengo?</Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>
                      Uso de tu tarjeta de presentación digital Güeya ilimitado.
                    </li>
                    <li>Personalización con tu logo, nombre etc.</li>
                    <li>Envío gratis a toda la república mexicana.</li>
                    <li>
                      Acceso a tu cuenta Güeya con la posibilidad de hacer
                      modificaciones en tiempo real.
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>¿Puedo facturar mi compra?</Accordion.Header>
                <Accordion.Body>
                  <p>
                    Si se puede factura compartiéndonos tus datos fiscales por
                    correo electrónico a{" "}
                    <a href="mailto:hola@gueya.io">hola@gueya.io</a>
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  ¿Cómo puedo activar el NFC en mi celular?
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Compartimos un{" "}
                    <a
                      href="https://m.youtube.com/watch?v=spLc2f0NxvU&feature=youtu.be"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      link
                    </a>{" "}
                    de un tutorial explicando como activar NFC en celulares
                    android.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Container>
        </div>
      </div>
      <div id="enterprise" className="enterprise">
        <Container>
          <Row>
            <Col
              xs="12"
              lg="6"
              className="d-flex flex-column justify-content-center enterprise__media"
            >
              {/* <img src={tarjetaGueya} alt="Güeya" className="img-fluid" /> */}
              <div className="gueya-card-carousel">
                <Carousel indicators={false} controls={false}>
                  <Carousel.Item>
                    <div className="carousel-item-inner">
                      <img
                        className="d-block w-100"
                        src={MPLogo}
                        alt="First slide"
                      />
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="carousel-item-inner carousel-item-inner--1">
                      <img
                        className="d-block w-100"
                        src={MrLuckyLogo}
                        alt="Second slide"
                      />
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="carousel-item-inner carousel-item-inner--2">
                      <img
                        className="d-block w-100"
                        src={GNPLogo}
                        alt="Second slide"
                      />
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="carousel-item-inner carousel-item-inner--3">
                      <img
                        className="d-block w-100"
                        src={DHLogo}
                        alt="Second slide"
                      />
                    </div>
                  </Carousel.Item>
                </Carousel>
              </div>
              <div className="enterprise__footer">
                <p>Seguro tenemos una solución para ti</p>
              </div>
            </Col>
            <Col xs="12" lg="6">
              <div className="enterprise__content">
                <h3>Güeya para empresas.</h3>
                <p className="mt-3 mt-lg-2">
                  Tenemos planes personalizados para empresas o grupos de
                  personas que quieran comprar un número mayor de Güeyas.
                </p>
                <a href="https://wa.me/524492990792">Quiero saber más</a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}

export default Home;
