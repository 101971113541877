import { useEffect } from "react";
import { useHistory } from "react-router-dom";

// Bootstrap components
import Container from "react-bootstrap/Container";

// Custom styles
import "../scss/pages/cardLinks.styles.scss";

// API
import { generateCardLinks } from "../api/auth";

// Context
import { useAuth } from "../contexts/AuthContext";

// Custom components
import { saveAs } from "file-saver";
import axios from "axios";

function CardLinks() {
  const { currentUser } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (!currentUser.admin) {
      history.push("/");
      // console.log("no hay usuario");
    }
  }, [currentUser]);
  return (
    <section className="card-links__wrapper">
      {currentUser.admin ? (
        <Container>
          <h2 className="text-center">Enlaces de tarjetas</h2>
          <div className="card-links__cta">
            <button
              className="me-3"
              onClick={async () => {
                // var file = new File(response, { autoBom: true });
                // saveAs(file);
                const config = { responseType: "blob" };
                axios
                  .get(
                    `${process.env.REACT_APP_API_URL}/admin/card-links/generate`,
                    config
                  )
                  .then((response) => {
                    saveAs(response.data, "Enlaces.xlsx");
                  });
                // const url = window.URL.createObjectURL(
                //   new Blob([response], {
                //     type: "application/vnd.ms-excel;charset=utf-8",
                //     encoding: "UTF-8",
                //   })
                // );
                // const link = document.createElement("a");
                // link.href = url;
                // link.setAttribute("download", response);
                // document.body.appendChild(link);
                // link.click();
                // link.remove();

                // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                //   // IE variant
                //   window.navigator.msSaveOrOpenBlob(
                //     new Blob([response.data], {
                //       type: "application/vnd.ms-excel;charset=utf-8",
                //       encoding: "UTF-8",
                //     }),
                //     response
                //   );
                // } else {
                //   const url = window.URL.createObjectURL(
                //     new Blob([response.data], {
                //       type: "application/vnd.ms-excel;charset=utf-8",
                //       encoding: "UTF-8",
                //     })
                //   );
                //   const link = document.createElement("a");
                //   link.href = url;
                //   link.setAttribute("download", response);
                //   document.body.appendChild(link);
                //   link.click();
                //   link.remove();
                // }
                // var filePlain = new File([response], "responsePlain.xlsx", {
                //   type: "text/plain;charset=utf-8",
                // });
                // var file = new File([response], "response.xlsx", {
                //   type: "application/vnd.ms-excel;charset=utf-8",
                // });
                // saveAs(file);
                // saveAs(filePlain);
              }}
            >
              Generar links Güeya Card
            </button>
            <button
              className="me-3"
              onClick={async () => {
                const config = { responseType: "blob" };
                axios
                  .get(
                    `${process.env.REACT_APP_API_URL}/admin/card-links/facebook/generate`,
                    config
                  )
                  .then((response) => {
                    saveAs(response.data, "Enlaces-Facebook.xlsx");
                  });
              }}
            >
              Generar links reviews Facebook
            </button>
            <button
              className="me-3"
              onClick={async () => {
                const config = { responseType: "blob" };
                axios
                  .get(
                    `${process.env.REACT_APP_API_URL}/admin/card-links/google/generate`,
                    config
                  )
                  .then((response) => {
                    saveAs(response.data, "Enlaces-Google.xlsx");
                  });
              }}
            >
              Generar links reviews Google
            </button>
            <button
              className="me-3"
              onClick={async () => {
                const config = { responseType: "blob" };
                axios
                  .get(
                    `${process.env.REACT_APP_API_URL}/admin/card-links/instagram/generate`,
                    config
                  )
                  .then((response) => {
                    saveAs(response.data, "Enlaces-Instagram.xlsx");
                  });
              }}
            >
              Generar links reviews Instagram
            </button>
            <button
              className="me-3"
              onClick={async () => {
                const config = { responseType: "blob" };
                axios
                  .get(
                    `${process.env.REACT_APP_API_URL}/admin/card-links/tripadvisor/generate`,
                    config
                  )
                  .then((response) => {
                    saveAs(response.data, "Enlaces-Tripadvisor.xlsx");
                  });
              }}
            >
              Generar links reviews Tripadvisor
            </button>
          </div>
        </Container>
      ) : null}
    </section>
  );
}

export default CardLinks;
