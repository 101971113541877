import { useState, useContext, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";

// Context
import AlertContext from "../contexts/AlertContext";

// Custom styles
import "../scss/pages/recoverPassword.scss";

// API;
import { resetPassword, resetWWWPassword } from "../api/auth";

// Custom components
// import ReCAPTCHA from "react-google-recaptcha";

// Formik for form validation
import { Formik } from "formik";
import * as yup from "yup";

const initialValues = {
  password: "",
};

const schema = yup.object().shape({
  password: yup
    .string()
    .min(8, "La contraseña debe ser igual o mayor a 8 caracteres")
    .required("Este campo es requerido"),
});

function RecoverPassword() {
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const [error, setError] = useState("");

  const captcha = useRef(null);
  const alert = useContext(AlertContext);
  const history = useHistory();

  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const token = query.get("token");

  // const urlParams = new URLSearchParams(window.location.search);
  // let token = urlParams.get("token");

  console.log(token);

  const onChange = (value) => {
    setError(false);
    // console.log("Captcha value:", value);
  };
  return (
    <section className="recover-password__wrapper">
      <Container>
        <div className="recover-password__container">
          <h4>Restablecer contraseña</h4>
          <p className="mb-3 mb-lg-0">
            Ingresa a continuación la nueva contraseña para tu cuenta
          </p>
          <Formik
            validationSchema={schema}
            onSubmit={async (values, { resetForm }) => {
              setResetPasswordLoading(true);
              values.token = token;
              const currentUrl = window.location.href;
              if (currentUrl.includes("www")) {
                const response = await resetWWWPassword(values);
                if (response.error) {
                  alert.error(response.message);
                } else if (response.error === false) {
                  alert.success(response.message);
                  setTimeout(() => {
                    history.push("/log-in");
                  }, 3000);
                }
                setResetPasswordLoading(false);
                console.log(values);
                resetForm(initialValues);
              } else {
                const response = await resetPassword(values);
                if (response.error) {
                  alert.error(response.message);
                } else if (response.error === false) {
                  alert.success(response.message);
                  setTimeout(() => {
                    history.push("/log-in");
                  }, 3000);
                }
                setResetPasswordLoading(false);
                console.log(values);
                resetForm(initialValues);
              }
              // captcha.current.reset();
            }}
            initialValues={initialValues}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className="mt-3" controlId="passwordInput">
                  <Form.Control
                    type="password"
                    placeholder="Escribe aquí la nueva contraseña"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.password && !!errors.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password || touched.password}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* <div className="re-captcha text-center">
                  <ReCAPTCHA
                    sitekey="6Le97hwjAAAAABgehJBi8UGDYphi-P9GeKGT7QsG"
                    onChange={onChange}
                    ref={captcha}
                  />
                </div> */}
                <button disabled={resetPasswordLoading} type="submit">
                  {resetPasswordLoading ? "Validando..." : "Restablecer"}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </section>
  );
}

export default RecoverPassword;
