import { createContext, useContext, useState, useEffect } from "react";

import {
  login,
  getCurrentUserProfile,
  register,
  updateProfile,
  wwwRegister,
  wwwLogin,
} from "../api/auth";

export const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);
  return context;
};

export function AuthProvider({ children }) {
  // const user = {
  //   login: false,
  // };

  const [currentUser, setUser] = useState({
    admin: false,
    user_id: 0,
    userInfo: {},
  });
  // const [authError, setAuthError] = useState("");

  const loginUser = async (values) => {
    // console.log(values, "authContext25");
    try {
      const response = await login(values);
      // console.log(response);
      if (!response.error) {
        setUser((prevState) => ({
          ...prevState, // keep all other key-value pairs
          user_id: response.user_id,
        }));
        // setUser({ ...currentUser, user_id: response.user_id });
        localStorage.setItem(
          "user",
          JSON.stringify({ user_id: response.user_id })
        );
        return response;
      } else {
        return {
          error: true,
          res: {
            error: true,
            type: response.res.type,
            message: response.res.message,
          },
        };
      }
    } catch (error) {
      console.log(error);
    }

    // ----
    // const response = {
    //   error: false,
    //   message: "Datos correctos, redireccionando a la pantalla principal...",
    //   url: "https://gueya.io/admin/account/profile",
    //   user_id: 61,
    // };
    // if (response.error) {
    //   return {
    //     error: true,
    //     res: {
    //       error: true,
    //       type: "password",
    //       message: "Incorrect username or password",
    //     },
    //   };
    // } else {
    //   setUser((prevState) => ({
    //     ...prevState, // keep all other key-value pairs
    //     user_id: response.user_id,
    //   }));
    //   // setUser({ ...currentUser, user_id: response.user_id });
    //   localStorage.setItem(
    //     "user",
    //     JSON.stringify({ user_id: response.user_id })
    //   );

    //   return response;
    // }
    // -----
  };

  const wwwLoginUser = async (values) => {
    // console.log(values, "authContext25");
    try {
      const response = await wwwLogin(values);
      // console.log(response);
      if (!response.error) {
        setUser((prevState) => ({
          ...prevState, // keep all other key-value pairs
          user_id: response.user_id,
        }));
        // setUser({ ...currentUser, user_id: response.user_id });
        localStorage.setItem(
          "user",
          JSON.stringify({ user_id: response.user_id })
        );
        return response;
      } else {
        return {
          error: true,
          res: {
            error: true,
            type: response.res.type,
            message: response.res.message,
          },
        };
      }
    } catch (error) {
      console.log(error);
    }
  };

  const registerUser = async (values) => {
    // console.log(values, "authContext87");
    try {
      const response = await register(values);
      // console.log(response);
      if (!response.error) {
        setUser((prevState) => ({
          ...prevState, // keep all other key-value pairs
          user_id: response.user_id,
        }));
        // setUser({ ...currentUser, user_id: response.user_id });
        localStorage.setItem(
          "user",
          JSON.stringify({ user_id: response.user_id })
        );
        return response;
      } else {
        return {
          error: true,
          res: {
            error: true,
            type: "password",
            message: "Incorrect username or password",
          },
        };
      }
    } catch (error) {
      console.log(error);
    }
  };

  const registerWWWUser = async (values) => {
    try {
      const response = await wwwRegister(values);
      if (!response.error) {
        setUser((prevState) => ({
          ...prevState, // keep all other key-value pairs
          user_id: response.user_id,
        }));
        // setUser({ ...currentUser, user_id: response.user_id });
        localStorage.setItem(
          "user",
          JSON.stringify({ user_id: response.user_id })
        );
        return response;
      } else {
        return {
          error: true,
          res: {
            error: true,
            type: "password",
            message: "Incorrect username or password",
          },
        };
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateProfileUser = async (values) => {
    try {
      const response = await updateProfile(values);
      // console.log(response);
      if (!response.error) {
        // setUser((prevState) => ({
        //   ...prevState, // keep all other key-value pairs
        //   user_id: response.user_id,
        // }));
        // // setUser({ ...currentUser, user_id: response.user_id });
        // localStorage.setItem(
        //   "user",
        //   JSON.stringify({ user_id: response.user_id })
        // );
        return response;
      } else {
        return {
          error: true,
          res: {
            error: true,
            type: "password",
            message: "Incorrect username or password",
          },
        };
      }
    } catch (error) {
      console.log(error);
    }
  };

  const logout = () => {
    localStorage.removeItem("user");
    setUser({ admin: false, userId: 0, userInfo: {} });
  };

  const isAuthenticated = () => {
    const currentUser = localStorage.getItem("user");
    if (!currentUser) {
      return null;
    }
    return JSON.parse(currentUser);
  };

  const getUserProfile = async (userId) => {
    // console.log(userId);

    try {
      const response = await getCurrentUserProfile(userId);
      // console.log(response);

      if (response.error) {
        // console.log("hubo error");
        return {
          error: true,
          res: {
            error: true,
            type: "password",
            message: "Incorrect username or password",
          },
        };
      } else {
        setUser((prevState) => ({
          ...prevState, // keep all other key-value pairs
          userInfo: response,
        }));
        localStorage.setItem(
          "user",
          JSON.stringify({ user_id: userId, userInfo: response.profile })
        );
        return response;
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const checkLoggedIn = async () => {
      let cuser = isAuthenticated();
      if (cuser === null) {
        // localStorage.setItem("user", { guest: true });
        cuser = { admin: false, userId: 0, userInfo: {} };
      }

      setUser(cuser);
    };

    checkLoggedIn();
  }, []);

  // console.log("usercontext", user);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        setUser,
        loginUser,
        logout,
        getUserProfile,
        registerUser,
        updateProfileUser,
        registerWWWUser,
        wwwLoginUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
