import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

// Custom styles
import "../scss/pages/accountProfile.styles.scss";

// API
import {
  postAvatarProfile,
  getWWWCurrentUserProfile,
  postWWWAvatarProfile,
  updateWWWProfile,
} from "../api/auth";

// Context
import AlertContext from "../contexts/AlertContext";
import { useAuth } from "../contexts/AuthContext";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

// Formik for form validation
import { Formik } from "formik";
import * as yup from "yup";

const schema = yup.object().shape({
  name: yup.string().required("Nombre es un campo requerido"),
  lastname: yup.string().required("Apellidos es un campo requerido"),
  phone: yup.number().required("Teléfono/WhatsApp es un campo requerido"),
  email: yup.string().email().required("Email es un campo requerido"),
  businessName: yup.string(),
  businessRole: yup.string(),
  businessWebsite: yup.string(),
  businessPhone: yup.number(),
  businessExt: yup.number(),
  businessAddress: yup.string(),
  businessNeighborhood: yup.string(),
  state: yup.string(),
  postalCode: yup.string(),
  country: yup.string(),
  tikTokLink: yup.string(),
  instagramLink: yup.string(),
  facebookLink: yup.string(),
  linkedInLink: yup.string(),
  // country: yup.string(),
});

function AccountProfile() {
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [imageUploadloading, setImageUploadLoading] = useState(false);
  const [currentProfile, setCurrentProfile] = useState({});
  const [currentImageId, setCurrentImageId] = useState(0);
  const [englishMode, setEnglishMode] = useState(false);

  const { getUserProfile, currentUser, updateProfileUser } = useAuth();
  // console.log(currentUser);

  const alert = useContext(AlertContext);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (currentUser.user_id > 0) {
        const currentUrl = window.location.href;
        if (currentUrl.includes("www")) {
          const fecth = await getWWWCurrentUserProfile(currentUser.user_id);
          // console.log(fecth);
          setCurrentProfile(fecth);
          setCurrentImageId(fecth.profile_img?.img_profile);
        } else {
          const fecth = await getUserProfile(currentUser.user_id);
          // console.log(fecth);
          setCurrentProfile(fecth);
          setCurrentImageId(fecth.profile_img?.img_profile);
        }
        setLoading(false);
      }
      // if (response.hasOwnProperty("profile")) {
      //   setLoading(false);
      // } else if (response.error) {
      //   setLoading(false);
      //   alert.error("Error en la solicitud de datos.");
      // }
      // if (currentUser?.user_id !== 0) {
      // }
      // setTimeout(async () => {
      // }, 4000);
    })();
  }, [currentUser.user_id, imageUploadloading]);

  useEffect(() => {
    if (!currentUser.user_id) {
      history.push("/");
      // console.log("no hay usuario");
    }
  }, [currentUser]);

  return (
    <section className="account-profile__wrapper">
      <div className="account-profile__container">
        {currentUser.user_id ? (
          <Container>
            <div className="account-profile__container__header">
              <h3>{englishMode ? "Edit profile" : "Editar perfil"}</h3>
              <p>
                {englishMode
                  ? "Empty fields will not be displayed in your Güeya profile."
                  : "Los campos vacíos no se mostarán en tu perfil de Güeya."}
              </p>
              <Form>
                <Form.Check // prettier-ignore
                  type="switch"
                  id="custom-switch"
                  label="English mode"
                  onClick={() => setEnglishMode(!englishMode)}
                />
              </Form>
            </div>
            <div className="account-profile__container__details">
              <div className="account-profile__container__personal-data">
                {loading ? (
                  <p>Cargando datos...</p>
                ) : (
                  <Formik
                    validationSchema={schema}
                    onSubmit={async (values, { resetForm }) => {
                      values.id_user = currentUser.user_id;
                      values.imgProfileId = currentImageId;
                      // console.log(values);
                      const response = await updateProfileUser(values);
                      setUpdateLoading(true);
                      if (response.error) {
                        alert.error(
                          "Hubo un error en tu solicitud. Por favor intenta de nuevo más tarde."
                        );
                        setUpdateLoading(false);
                      } else {
                        alert.success("Perfil Actualizado");
                        setUpdateLoading(false);
                        window.scrollTo(0, 0);
                      }
                      // const response = await login(
                      //   values.username,
                      //   values.password
                      // );
                      // console.log(response);
                      // if (!response) {
                      //   alert.error("Error al comunicarse con el servidor.");
                      //   setLoading(false);
                      //   return;
                      // }
                      // if (response.status === 200) {
                      //   history.push("/");
                      //   setLoading(false);
                      // } else {
                      //   // console.log("error aqui bro");
                      //   response.json().then((json) => {
                      //     if (json.error === "INVALID_PASSWORD") {
                      //       alert.error("Contraseña incorrecta.");
                      //     } else if (json.error === "EMAIL_NOT_FOUND") {
                      //       alert.error("Email no encontrado.");
                      //     } else if (
                      //       json.error ===
                      //       "TOO_MANY_ATTEMPTS_TRY_LATER : Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later."
                      //     ) {
                      //       alert.error(
                      //         "Excediste los intentos permitidos. Espera un momento o restaura tu contraseña."
                      //       );
                      //     } else {
                      //       alert.error(json.error);
                      //     }
                      //   });
                      //   setLoading(false);
                      // }
                      // alert.success("Submitted!");
                      // resetForm({
                      //   name: "",
                      //   lastname: "",
                      //   phone: "",
                      //   email: "",
                      //   businessName: "",
                      //   businessRole: "",
                      //   businessWebsite: "",
                      //   businessPhone: "",
                      //   businessExt: "",
                      //   businessAddress: "",
                      //   businessNeighborhood: "",
                      //   state: "",
                      //   postalCode: "",
                      //   country: "",
                      //   tikTokLink: "",
                      //   instagramLink: "",
                      //   facebookLink: "",
                      //   linkedInLink: "",
                      //   // countryPhoneOrigin: "",
                      //   // avatar: "",
                      // });
                    }}
                    initialValues={{
                      name: currentProfile?.profile?.name,
                      lastname: currentProfile?.profile?.last_name,
                      phone: currentProfile?.profile?.phone,
                      email: currentProfile?.profile?.email,
                      businessName: currentProfile?.profile?.company,
                      businessRole: currentProfile?.profile?.position,
                      businessWebsite: currentProfile?.profile?.web_page,
                      businessPhone: currentProfile?.profile?.work_telephone,
                      businessExt: currentProfile?.profile?.ext,
                      businessAddress: currentProfile?.profile?.address,
                      businessNeighborhood: currentProfile?.profile?.colonia,
                      state: currentProfile?.profile?.estado,
                      postalCode: currentProfile?.profile?.cp,
                      country: currentProfile?.profile?.country,
                      tikTokLink: currentProfile?.profile?.tiktok,
                      instagramLink: currentProfile?.profile?.instagram,
                      facebookLink: currentProfile?.profile?.facebook,
                      linkedInLink: currentProfile?.profile?.linkedin,
                      // countryPhoneOrigin: currentProfile?.profile.country,
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                      values,
                      touched,
                      isValid,
                      errors,
                    }) => (
                      <Container>
                        <Form noValidate onSubmit={handleSubmit}>
                          <h5>
                            {englishMode
                              ? "Personal data:"
                              : "Datos personales:"}
                          </h5>
                          <Row>
                            <Col
                              xs="12"
                              lg="4"
                              className="d-flex justify-content-center align-items-center mb-2 mb-lg-0"
                            >
                              <div className="account-profile__container__personal-data__media">
                                <div className="account-profile__container__personal-data__media__container">
                                  <img
                                    src={currentProfile?.profile_img?.img_path}
                                    alt="Güeya"
                                  />
                                </div>
                                <input
                                  type="file"
                                  name="avatar"
                                  onChange={(event) => {
                                    setFieldValue(
                                      "avatar",
                                      event.currentTarget.files[0]
                                    );
                                  }}
                                />
                                {values.avatar !== undefined ? (
                                  <button
                                    type="button"
                                    onClick={async () => {
                                      setImageUploadLoading(true);
                                      const currentUrl = window.location.href;
                                      if (currentUrl.includes("www")) {
                                        const responseAvatar =
                                          await postWWWAvatarProfile(
                                            values.avatar
                                          ).then(async (res) => {
                                            // console.log(res, "res de 231");
                                            values.id_user =
                                              currentUser.user_id;
                                            values.imgProfileId = res;
                                            const response =
                                              await updateWWWProfile(values);
                                            if (response.error) {
                                              setImageUploadLoading(false);
                                              alert.error(
                                                "Hubo un error en tu solicitud. Por favor intenta de nuevo más tarde."
                                              );
                                              setUpdateLoading(false);
                                            } else {
                                              setImageUploadLoading(false);
                                              alert.success(
                                                "Perfil Actualizado"
                                              );
                                              setUpdateLoading(false);
                                              window.scrollTo(0, 0);
                                            }
                                          });
                                      } else {
                                        const responseAvatar =
                                          await postAvatarProfile(
                                            values.avatar
                                          ).then(async (res) => {
                                            // console.log(res, "res de 231");
                                            values.id_user =
                                              currentUser.user_id;
                                            values.imgProfileId = res;
                                            const response =
                                              await updateProfileUser(values);
                                            if (response.error) {
                                              setImageUploadLoading(false);
                                              alert.error(
                                                "Hubo un error en tu solicitud. Por favor intenta de nuevo más tarde."
                                              );
                                              setUpdateLoading(false);
                                            } else {
                                              setImageUploadLoading(false);
                                              alert.success(
                                                "Perfil Actualizado"
                                              );
                                              setUpdateLoading(false);
                                              window.scrollTo(0, 0);
                                            }
                                          });
                                      }

                                      // console.log(response, "response de post");
                                      values.avatar = undefined;
                                    }}
                                  >
                                    {imageUploadloading
                                      ? "Subiendo imagen..."
                                      : "Actualizar imagen"}
                                  </button>
                                ) : null}
                              </div>
                              {/* <Form.Group className="mb-3" controlId="formName2">
                            <Form.Control
                              type="text"
                              placeholder="Nombre"
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.name && !!errors.name}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.name || touched.name}
                            </Form.Control.Feedback>
                          </Form.Group> */}
                            </Col>
                            <Col
                              xs="12"
                              lg="8"
                              className="d-flex flex-column justify-content-center"
                            >
                              <Row>
                                <Form.Group
                                  as={Col}
                                  lg="6"
                                  className="mb-3"
                                  controlId="formName"
                                >
                                  {/* <Form.Label>Nombre de usuario:</Form.Label> */}
                                  <Form.Control
                                    type="text"
                                    placeholder={
                                      englishMode ? "Name:" : "Nombre:"
                                    }
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.name && !!errors.name}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.name || touched.name}
                                  </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                  as={Col}
                                  lg="6"
                                  className="mb-3"
                                  controlId="formLastName"
                                >
                                  {/* <Form.Label>Contraseña:</Form.Label> */}
                                  <Form.Control
                                    type="text"
                                    placeholder={
                                      englishMode ? "Lastname:" : "Apellido(s)"
                                    }
                                    name="lastname"
                                    value={values.lastname}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={
                                      touched.lastname && !!errors.lastname
                                    }
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.lastname || touched.lastname}
                                  </Form.Control.Feedback>
                                </Form.Group>
                                {/* <Form.Group
                                  as={Col}
                                  lg="6"
                                  className="mb-3"
                                  controlId="formPhone"
                                >
                                  <Form.Select
                                    aria-label="País"
                                    name="countryPhoneOrigin"
                                    value={values.countryPhoneOrigin}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={
                                      touched.countryPhoneOrigin &&
                                      !!errors.countryPhoneOrigin
                                    }
                                  >
                                    {" "}
                                    <option>País</option>
                                    <option value="1">México</option>
                                    <option value="2">U.S.A</option>
                                  </Form.Select>
                                </Form.Group> */}
                                <Form.Group
                                  as={Col}
                                  lg="6"
                                  className="mb-3"
                                  controlId="formPhone"
                                >
                                  {/* <Form.Label>Nombre de usuario:</Form.Label> */}
                                  <Form.Control
                                    type="text"
                                    placeholder={
                                      englishMode
                                        ? "Cellphone/WhatsApp:"
                                        : "Telefono celular / WhatsApp"
                                    }
                                    name="phone"
                                    value={values.phone}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.phone && !!errors.phone}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.phone || touched.phone}
                                  </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                  as={Col}
                                  lg="6"
                                  className="mb-3"
                                  controlId="formEmail"
                                >
                                  {/* <Form.Label>Contraseña:</Form.Label> */}
                                  <Form.Control
                                    type="text"
                                    placeholder={
                                      englishMode
                                        ? "Email:"
                                        : "Correo electrónico:"
                                    }
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.email && !!errors.email}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.email || touched.email}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Row>
                            </Col>
                          </Row>
                          <div className="grey mt-2 mt-lg-3"></div>
                          <h5 className="pt-3">
                            {englishMode ? "Work data:" : "Datos laborales:"}
                          </h5>
                          <Row>
                            <Form.Group
                              as={Col}
                              lg="4"
                              className="mb-3"
                              controlId="formCountry"
                            >
                              <Form.Select
                                aria-label="Default select example"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="country"
                              >
                                <option>
                                  {currentProfile?.profile?.country}
                                </option>
                                {/* <option>
                                  {currentProfile.profile.country}
                                </option> */}
                                <option
                                  value={`${
                                    currentProfile?.profile?.country ===
                                      "México" ||
                                    currentProfile?.profile?.country ===
                                      "Mexico"
                                      ? "U.S.A"
                                      : "México"
                                  }`}
                                >{`${
                                  currentProfile?.profile?.country ===
                                    "México" ||
                                  currentProfile?.profile?.country === "Mexico"
                                    ? "U.S.A"
                                    : "México"
                                }`}</option>
                                {/* <option value="USA">U.S.A</option> */}
                                {/* {values.country === null ? (
                                  <>
                                    <option value="México">México</option>{" "}
                                    <option value="USA">U.S.A</option>
                                  </>
                                ) : values.country === "México" ||
                                  values.country === "Mexico" ? (
                                  <option value="USA">U.S.A</option>
                                ) : (
                                  <option value="México">México</option>
                                )} */}
                                {/* <option value="1">One</option> */}
                                {/* <option value="2">Two</option>
                                <option value="3">Three</option> */}
                              </Form.Select>
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              lg="4"
                              className="mb-3"
                              controlId="formBusinessName"
                            >
                              {/* <Form.Label>Nombre de usuario:</Form.Label> */}
                              <Form.Control
                                type="text"
                                placeholder={
                                  englishMode
                                    ? "Business name:"
                                    : "Nombre de la empresa"
                                }
                                name="businessName"
                                value={
                                  values.businessName === null
                                    ? (values.businessName = "")
                                    : values.businessName
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={
                                  touched.businessName && !!errors.businessName
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.businessName || touched.businessName}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              lg="4"
                              className="mb-3"
                              controlId="formBusinessRole"
                            >
                              {/* <Form.Label>Contraseña:</Form.Label> */}
                              <Form.Control
                                type="text"
                                placeholder={
                                  englishMode ? "Position:" : "Puesto:"
                                }
                                name="businessRole"
                                value={
                                  values.businessRole === null
                                    ? (values.businessRole = "")
                                    : values.businessRole
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={
                                  touched.businessRole && !!errors.businessRole
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.businessRole || touched.businessRole}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              lg="4"
                              className="mb-3"
                              controlId="formBusinessWebsite"
                            >
                              {/* <Form.Label>Nombre de usuario:</Form.Label> */}
                              <Form.Control
                                type="text"
                                placeholder={
                                  englishMode
                                    ? "Business website:"
                                    : "Sitio web empresa:"
                                }
                                name="businessWebsite"
                                value={
                                  values.businessWebsite === null
                                    ? (values.businessWebsite = "")
                                    : values.businessWebsite
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={
                                  touched.businessWebsite &&
                                  !!errors.businessWebsite
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.businessWebsite ||
                                  touched.businessWebsite}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              lg="4"
                              className="mb-3"
                              controlId="formBusinessPhone"
                            >
                              {/* <Form.Label>Contraseña:</Form.Label> */}
                              <Form.Control
                                type="text"
                                placeholder={
                                  englishMode
                                    ? "Business phone:"
                                    : "Teléfono empresa:"
                                }
                                name="businessPhone"
                                value={
                                  values.businessPhone === null
                                    ? (values.businessPhone = "")
                                    : values.businessPhone
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={
                                  touched.businessPhone &&
                                  !!errors.businessPhone
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.businessPhone || touched.businessPhone}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              lg="4"
                              className="mb-3"
                              controlId="formBusinessExt"
                            >
                              {/* <Form.Label>Contraseña:</Form.Label> */}
                              <Form.Control
                                type="text"
                                placeholder={
                                  englishMode ? "Extension:" : "Extensión:"
                                }
                                name="businessExt"
                                value={
                                  values.businessExt === null
                                    ? (values.businessExt = "")
                                    : values.businessExt
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={
                                  touched.businessExt && !!errors.businessExt
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.businessExt || touched.businessExt}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              lg="4"
                              className="mb-3"
                              controlId="formBusinessAddress"
                            >
                              {/* <Form.Label>Contraseña:</Form.Label> */}
                              <Form.Control
                                type="text"
                                placeholder={
                                  englishMode
                                    ? "Address (street & number):"
                                    : "Dirección (calle y número)"
                                }
                                name="businessAddress"
                                value={
                                  values.businessAddress === null
                                    ? (values.businessAddress = "")
                                    : values.businessAddress
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={
                                  touched.businessAddress &&
                                  !!errors.businessAddress
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.businessAddress ||
                                  touched.businessAddress}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              lg="4"
                              className="mb-3"
                              controlId="formBusinessNeighborhood"
                            >
                              {/* <Form.Label>Contraseña:</Form.Label> */}
                              <Form.Control
                                type="text"
                                placeholder={
                                  englishMode ? "Neighborhood:" : "Colonia"
                                }
                                name="businessNeighborhood"
                                value={
                                  values.businessNeighborhood === null
                                    ? (values.businessNeighborhood = "")
                                    : values.businessNeighborhood
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={
                                  touched.businessNeighborhood &&
                                  !!errors.businessNeighborhood
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.businessNeighborhood ||
                                  touched.businessNeighborhood}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              lg="4"
                              className="mb-3"
                              controlId="formState"
                            >
                              {/* <Form.Label>Contraseña:</Form.Label> */}
                              <Form.Control
                                type="text"
                                placeholder={englishMode ? "State:" : "Estado:"}
                                name="state"
                                value={
                                  values.state === null
                                    ? (values.state = "")
                                    : values.state
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.state && !!errors.state}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.state || touched.state}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              lg="4"
                              className="mb-3"
                              controlId="formPostalCode"
                            >
                              {/* <Form.Label>Contraseña:</Form.Label> */}
                              <Form.Control
                                type="text"
                                placeholder={
                                  englishMode
                                    ? "Postal code:"
                                    : "Código postal:"
                                }
                                name="postalCode"
                                value={
                                  values.postalCode === null
                                    ? (values.postalCode = "")
                                    : values.postalCode
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={
                                  touched.postalCode && !!errors.postalCode
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.postalCode || touched.postalCode}
                              </Form.Control.Feedback>
                            </Form.Group>
                            {/* <Form.Group
                              as={Col}
                              lg="4"
                              className="mb-3"
                              controlId="formCountry"
                            >
                              <Form.Control
                                type="text"
                                placeholder="País"
                                name="country"
                                value={
                                  values.country === null
                                    ? (values.country = "")
                                    : values.country
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.country && !!errors.country}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.country || touched.country}
                              </Form.Control.Feedback>
                            </Form.Group> */}
                          </Row>
                          <div className="grey"></div>
                          <h5 className="pt-3">
                            {englishMode
                              ? "Social information"
                              : "Redes sociales:"}
                          </h5>
                          <Row>
                            <Form.Group
                              as={Col}
                              lg="4"
                              className="mb-3"
                              controlId="formTikTok"
                            >
                              {/* <Form.Label>Nombre de usuario:</Form.Label> */}
                              <Form.Control
                                type="text"
                                placeholder="TikTok"
                                name="tikTokLink"
                                value={
                                  values.tikTokLink === null
                                    ? (values.tikTokLink = "")
                                    : values.tikTokLink
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={
                                  touched.tikTokLink && !!errors.tikTokLink
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.tikTokLink || touched.tikTokLink}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              lg="4"
                              className="mb-3"
                              controlId="formInstagram"
                            >
                              {/* <Form.Label>Contraseña:</Form.Label> */}
                              <Form.Control
                                type="text"
                                placeholder="Instagram"
                                name="instagramLink"
                                value={
                                  values.instagramLink === null
                                    ? (values.instagramLink = "")
                                    : values.instagramLink
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={
                                  touched.instagramLink &&
                                  !!errors.instagramLink
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.instagramLink || touched.instagramLink}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              lg="4"
                              className="mb-3"
                              controlId="formFacebook"
                            >
                              {/* <Form.Label>Nombre de usuario:</Form.Label> */}
                              <Form.Control
                                type="text"
                                placeholder="Facebook"
                                name="facebookLink"
                                value={
                                  values.facebookLink === null
                                    ? (values.facebookLink = "")
                                    : values.facebookLink
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={
                                  touched.facebookLink && !!errors.facebookLink
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.facebookLink || touched.facebookLink}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              lg="4"
                              className="mb-3"
                              controlId="formLinkedin"
                            >
                              {/* <Form.Label>Contraseña:</Form.Label> */}
                              <Form.Control
                                type="text"
                                placeholder="LinkedIn"
                                name="linkedInLink"
                                value={
                                  values.linkedInLink === null
                                    ? (values.linkedInLink = "")
                                    : values.linkedInLink
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={
                                  touched.linkedInLink && !!errors.linkedInLink
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.linkedInLink || touched.linkedInLink}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Row>
                          {/* <Link to="/recover" className="d-block text-center">
                ¿Olvidaste tu contraseña?
              </Link> */}
                          <button
                            type="submit"
                            // disabled={values.avatar !== undefined}
                          >
                            {updateLoading
                              ? englishMode
                                ? "Saving data..."
                                : "Guardando cambios..."
                              : englishMode
                              ? "Save data"
                              : "Guardar cambios"}
                          </button>
                        </Form>
                      </Container>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </Container>
        ) : null}
      </div>
    </section>
  );
}

export default AccountProfile;
