import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

// API
import {
  postAvatarProfile,
  postWWWAvatarProfile,
  updateProfile,
  updateWWWProfile,
  getWWWProfile,
  getProfile,
} from "../api/auth";

// Custom styles
import "../scss/pages/profile.styles.scss";

// Bootstrap components
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";

// Assets
import logo from "../assets/img/logo-mod.png";

// Formik for form validation
import { Formik } from "formik";
import * as yup from "yup";

const schema = yup.object().shape({
  businessName: yup.string().required("Este campo es requerido"),
});

const initialValues = {
  name: "",
  lastname: "",
  phone: "",
  email: "",
  emailConfirmation: "",
  username: "",
  password: "",
  passwordConfirmation: "",
};

function FacebookProfile() {
  let { link } = useParams();

  const [loading, setLoading] = useState(false);
  const [currentProfile, setCurrentProfile] = useState({});

  // console.log("link", link);
  // console.log("currentProfile", currentProfile);

  const history = useHistory();

  // console.log(Object.keys(currentProfile).length);
  useEffect(() => {
    (async () => {
      setLoading(true);
      const currentUrl = window.location.href;
      if (currentUrl.includes("www")) {
        const fetch = await getWWWProfile(link).then((res) => {
          if (res.error === true) {
            history.push(`/facebook/sign-up/${link}`);
          } else if (res.error === false) {
            setCurrentProfile(res.profile);
            setLoading(false);
          } else {
            setCurrentProfile({});
            setLoading(false);
          }
          // console.log(res);
        });
      } else {
        const fetch = await getProfile(link).then((res) => {
          if (res.error === true) {
            history.push(`/facebook/sign-up/${link}`);
          } else if (res.error === false) {
            setCurrentProfile(res.profile);
            setLoading(false);
          } else {
            setCurrentProfile({});
            setLoading(false);
          }
          // console.log(res);
        });
      }
    })();
  }, [link]);

  return (
    <section className="register__wrapper register__wrapper--reviews">
      <div className="register__container">
        <img src={logo} alt="Güeya" className="register__logo" />
        <h3>Configuración de cuenta</h3>
        <p className="mt-2">
          Proporciona el nombre de tu negocio en Google así como la dirección:
        </p>
        <Formik
          validationSchema={schema}
          initialValues={initialValues}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setLoading(true);
            values.link = link;
            // console.log(values);
            const currentUrl = window.location.href;
            // if (currentUrl.includes("www")) {
            //   const response = await registerWWWUser(values).then(
            //     async (res) => {
            //       // console.log(res);
            //       if (
            //         res.error === true &&
            //         res.message === "Username or Email already exists"
            //       ) {
            //         alert.error("El nombre de usuario o email ya existen.");
            //         setLoading(false);
            //         return;
            //       } else if (
            //         res.error === false &&
            //         res.message ===
            //           "Your registration was completed successfully"
            //       ) {
            //         setImageUploadLoading(true);
            //         const responseAvatar = await postWWWAvatarProfile(
            //           values.avatar
            //         ).then(async (r) => {
            //           // console.log(r, "r");
            //           // values.lastname = values.lastname;
            //           values.id_user = res.user_id;
            //           values.imgProfileId = r;
            //           values.businessName = "";
            //           values.businessRole = "";
            //           values.businessWebsite = "";
            //           values.businessPhone = "";
            //           values.businessExt = "";
            //           values.businessAddress = "";
            //           values.businessNeighborhood = "";
            //           values.state = "";
            //           values.postalCode = "";
            //           values.country = "";
            //           values.tikTokLink = "";
            //           values.instagramLink = "";
            //           values.facebookLink = "";
            //           values.linkedInLink = "";
            //           const response = await updateWWWProfile(values);
            //           if (response.error) {
            //             setImageUploadLoading(false);
            //             alert.error(
            //               "Hubo un error en tu solicitud. Por favor intenta de nuevo más tarde."
            //             );
            //             setLoading(false);
            //             setImageUploadLoading(false);
            //           } else {
            //             // setImageUploadLoading(false);
            //             // alert.success(
            //             //   "Perfil Actualizado"
            //             // );
            //             // setUpdateLoading(false);
            //             // window.scrollTo(0, 0);
            //             alert.success("Registro exitoso.");
            //             history.push("/log-in");
            //             setLoading(false);
            //             setImageUploadLoading(false);
            //           }
            //         });
            //         return;
            //       } else {
            //         alert.error(
            //           "Error en tu solicitud. Por favor intenta de nuevo más tarde"
            //         );
            //         setLoading(false);
            //         return;
            //       }
            //     }
            //   );
            // } else {
            //   const response = await registerUser(values).then(async (res) => {
            //     if (
            //       res.error === true &&
            //       res.message === "Username or Email already exists"
            //     ) {
            //       alert.error("El nombre de usuario o email ya existen.");
            //       return;
            //     } else if (
            //       res.error === false &&
            //       res.message === "Your registration was completed successfully"
            //     ) {
            //       setImageUploadLoading(true);
            //       const responseAvatar = await postAvatarProfile(
            //         values.avatar
            //       ).then(async (r) => {
            //         if (typeof r == "number") {
            //           // console.log(r, "r");
            //           // values.lastname = values.lastname;
            //           values.id_user = res.user_id;
            //           values.imgProfileId = r;
            //           values.businessName = "";
            //           values.businessRole = "";
            //           values.businessWebsite = "";
            //           values.businessPhone = "";
            //           values.businessExt = "";
            //           values.businessAddress = "";
            //           values.businessNeighborhood = "";
            //           values.state = "";
            //           values.postalCode = "";
            //           values.country = "";
            //           values.tikTokLink = "";
            //           values.instagramLink = "";
            //           values.facebookLink = "";
            //           values.linkedInLink = "";
            //           const response = await updateProfile(values);
            //           if (response.error) {
            //             setImageUploadLoading(false);
            //             alert.error(
            //               "Hubo un error en tu solicitud. Por favor intenta de nuevo más tarde."
            //             );
            //             setLoading(false);
            //             // setImageUploadLoading(false);
            //           } else {
            //             // setImageUploadLoading(false);
            //             // alert.success(
            //             //   "Perfil Actualizado"
            //             // );
            //             // setUpdateLoading(false);
            //             // window.scrollTo(0, 0);
            //             alert.success("Registro exitoso.");
            //             history.push("/log-in");
            //             setLoading(false);
            //             setImageUploadLoading(false);
            //           }
            //         } else {
            //           alert.error(
            //             "El tamaño de la imagen seleccionada supera los 2.5Mb permitidos."
            //           );
            //           setImageUploadLoading(false);
            //           setLoading(false);
            //         }
            //       });
            //       return;
            //     } else {
            //       alert.error(
            //         "Error en tu solicitud. Por favor intenta de nuevo más tarde"
            //       );
            //       setImageUploadLoading(false);
            //       setLoading(false);
            //       return;
            //     }
            //   });
            // }
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group controlId="formRegisterName" className="mb-3">
                <Form.Label>Nombre de tu negocio en Facebook:*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Escribe tu nombre"
                  name="businessName"
                  value={values.businessName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.businessName && touched.businessName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.businessName || touched.businessName}
                </Form.Control.Feedback>
              </Form.Group>
              <button type="submit" disabled={loading}>
                {loading ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Cargando...</span>
                  </Spinner>
                ) : (
                  "Guardar datos"
                )}
              </button>
              {/* <p>
              ¿Ya tienes cuenta? <Link to="/log-in">Inicia sesión aquí</Link>
            </p> */}
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
}

export default FacebookProfile;
