/*
  @route    POST REACT_APP_API_URL/account/edit-social-profile
  @desc     POST new social goog leprofile
  @access   Public
*/

export async function postGoogleProfile(formData) {
  // console.log(image);
  const data = new FormData();
  // data.append("profile_img", { color: null });
  data.append("link", formData.link);
  data.append("google_profile", formData.googlePlaceId);
  console.log("data sent:", data);
  try {
    const url = `${process.env.REACT_APP_API_URL}/site/account/edit-social-profile`;
    const params = {
      method: "POST",
      // mode: "no-cors",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: data,
    };
    const response = await fetch(url, params);
    // console.log(response, "response de auth.178");
    const result = await response.json();
    // console.log(result, "result de auth.180");
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
}

/*
  @route    POST REACT_APP_API_URL/site/profile/get-google-profile
  @desc     Get a google profile to check existance
  @access   Public
*/

export async function getGoogleProfile(link) {
  // console.log(link);
  const data = new FormData();
  data.append("link", link);
  try {
    const url = `${process.env.REACT_APP_API_URL}/site/profile/get-google-profile`;
    const params = {
      method: "POST",
      // mode: "no-cors",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: data,
    };
    const response = await fetch(url, params);
    const result = await response.json();
    console.log(result);
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
}

/*
  @route    POST https://www.gueya.io/ajax/site/profile/get-google-profile
  @desc     Get a google profile to check existance
  @access   Public
*/

export async function getWWWGoogleProfile(link) {
  // console.log(link);
  const data = new FormData();
  data.append("link", link);
  try {
    const url = `https://www.gueya.io/ajax/site/profile/get-google-profile`;
    const params = {
      method: "POST",
      // mode: "no-cors",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: data,
    };
    const response = await fetch(url, params);
    const result = await response.json();
    console.log(result);
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
}

/*
  @route    POST REACT_APP_API_URL/site/profile/get-facebook-profile
  @desc     Get a facebook profile to check existance
  @access   Public
*/

export async function getFacebookProfile(link) {
  // console.log(link);
  const data = new FormData();
  data.append("link", link);
  try {
    const url = `${process.env.REACT_APP_API_URL}/site/profile/get-facebook-profile`;
    const params = {
      method: "POST",
      // mode: "no-cors",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: data,
    };
    const response = await fetch(url, params);
    const result = await response.json();
    console.log(result);
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
}

/*
  @route    POST https://www.gueya.io/ajax/site/profile/get-facebook-profile
  @desc     Get a facebook profile to check existance
  @access   Public
*/

export async function getWWWFacebookProfile(link) {
  // console.log(link);
  const data = new FormData();
  data.append("link", link);
  try {
    const url = `https://www.gueya.io/ajax/site/profile/get-facebook-profile`;
    const params = {
      method: "POST",
      // mode: "no-cors",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: data,
    };
    const response = await fetch(url, params);
    const result = await response.json();
    console.log(result);
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
}

/*
  @route    POST REACT_APP_API_URL/account/edit-facebook-social-profile
  @desc     POST new social goog leprofile
  @access   Public
*/

export async function postFacebookProfile(formData) {
  // console.log(image);
  const data = new FormData();
  // data.append("profile_img", { color: null });
  data.append("link", formData.link);
  data.append("facebook_profile", formData.facebookUsername);
  // console.log("data sent:", data);
  try {
    const url = `${process.env.REACT_APP_API_URL}/site/account/edit-facebook-social-profile`;
    const params = {
      method: "POST",
      // mode: "no-cors",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: data,
    };
    const response = await fetch(url, params);
    // console.log(response, "response de auth.178");
    const result = await response.json();
    // console.log(result, "result de auth.180");
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
}

/*
  @route    POST REACT_APP_API_URL/site/profile/get-instagram-profile
  @desc     Get a instagram profile to check existance
  @access   Public
*/

export async function getInstagramProfile(link) {
  // console.log(link);
  const data = new FormData();
  data.append("link", link);
  try {
    const url = `${process.env.REACT_APP_API_URL}/site/profile/get-instagram-profile`;
    const params = {
      method: "POST",
      // mode: "no-cors",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: data,
    };
    const response = await fetch(url, params);
    const result = await response.json();
    console.log(result);
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
}

/*
  @route    POST https://www.gueya.io/ajax/site/profile/get-instagram-profile
  @desc     Get a instagram profile to check existance
  @access   Public
*/

export async function getWWWInstagramProfile(link) {
  // console.log(link);
  const data = new FormData();
  data.append("link", link);
  try {
    const url = `https://www.gueya.io/ajax/site/profile/get-instagram-profile`;
    const params = {
      method: "POST",
      // mode: "no-cors",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: data,
    };
    const response = await fetch(url, params);
    const result = await response.json();
    console.log(result);
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
}

/*
  @route    POST REACT_APP_API_URL/account/edit-instagram-social-profile
  @desc     POST new social instagram profile
  @access   Public
*/

export async function postInstagramProfile(formData) {
  // console.log(image);
  const data = new FormData();
  // data.append("profile_img", { color: null });
  data.append("link", formData.link);
  data.append("ig_profile", formData.instagramUsername);
  // console.log("data sent:", data);
  try {
    const url = `${process.env.REACT_APP_API_URL}/site/account/edit-instagram-social-profile`;
    const params = {
      method: "POST",
      // mode: "no-cors",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: data,
    };
    const response = await fetch(url, params);
    // console.log(response, "response de auth.178");
    const result = await response.json();
    // console.log(result, "result de auth.180");
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
}

/*
  @route    POST REACT_APP_API_URL/account/edit-instagram-social-profile
  @desc     POST new social Trip Advisor profile
  @access   Public
*/

export async function postTripAdvisorProfile(formData) {
  // console.log(image);
  const data = new FormData();
  // data.append("profile_img", { color: null });
  data.append("link", formData.link);
  data.append("tripadvisor_profile", formData.tripAdvisorBusiness);
  // console.log("data sent:", data);
  try {
    const url = `${process.env.REACT_APP_API_URL}/site/account/edit-tripadvisor-social-profile`;
    const params = {
      method: "POST",
      // mode: "no-cors",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: data,
    };
    const response = await fetch(url, params);
    // console.log(response, "response de auth.178");
    const result = await response.json();
    // console.log(result, "result de auth.180");
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
}

/*
  @route    POST REACT_APP_API_URL/site/profile/get-tripadvisor-profile
  @desc     Get a tripadvisor profile to check existance
  @access   Public
*/

export async function getTripAdvisorProfile(link) {
  // console.log(link);
  const data = new FormData();
  data.append("link", link);
  try {
    const url = `${process.env.REACT_APP_API_URL}/site/profile/get-tripadvisor-profile`;
    const params = {
      method: "POST",
      // mode: "no-cors",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: data,
    };
    const response = await fetch(url, params);
    const result = await response.json();
    console.log(result);
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
}

/*
  @route    POST https://www.gueya.io/ajax/site/profile/get-tripadvisor-profile
  @desc     Get a tripadvisor profile to check existance
  @access   Public
*/

export async function getWWWTripAdvisorProfile(link) {
  // console.log(link);
  const data = new FormData();
  data.append("link", link);
  try {
    const url = `https://www.gueya.io/ajax/site/profile/get-tripadvisor-profile`;
    const params = {
      method: "POST",
      // mode: "no-cors",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: data,
    };
    const response = await fetch(url, params);
    const result = await response.json();
    console.log(result);
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
}
