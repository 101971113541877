import { useEffect, useContext, useState } from "react";
import { useParams } from "react-router";
import { Link, useHistory } from "react-router-dom";

// Bootstrap components
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

// API
import {
  postAvatarProfile,
  postWWWAvatarProfile,
  updateProfile,
  updateWWWProfile,
  getWWWProfile,
  getProfile,
} from "../api/auth";

// Context
import AlertContext from "../contexts/AlertContext";
import { useAuth } from "../contexts/AuthContext";

// Custom styles
import "../scss/pages/register.styles.scss";

// Assets
import logo from "../assets/img/logo-gueya.png";

// Formik for form validation
import { Formik } from "formik";
import * as yup from "yup";

const initialValues = {
  name: "",
  lastname: "",
  phone: "",
  email: "",
  emailConfirmation: "",
  username: "",
  password: "",
  passwordConfirmation: "",
};

const schema = yup.object().shape({
  name: yup.string().required("Este campo es requerido"),
  lastname: yup.string().required("Este campo es requerido"),
  //lastnameMother: yup.string().required("Este campo es requerido"),
  phone: yup.number().required("Este campo es requerido"),
  email: yup
    .string()
    .email("Ingresa un email válido")
    .required("Este campo es requerido"),
  emailConfirmation: yup
    .string()
    .oneOf([yup.ref("email"), null], "Los email deben coincidir")
    .email("Ingresa un email válido")
    .required("Este campo es requerido"),
  username: yup.string().required("Este campo es requerido"),
  password: yup
    .string()
    .min(8, "La contraseña debe ser igual o mayor a 8 caracteres")
    .required("Este campo es requerido"),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Las contraseñas deben coincidir")
    .min(8, "La contraseña debe ser igual o mayor a 8 caracteres")
    .required("Este campo es requerido"),
  // terms: yup
  //   .bool()
  //   .required()
  //   .oneOf(
  //     [true],
  //     "Los términos y condiciones deben ser aceptados para continuar"
  //   ),
});

function Register() {
  let { link } = useParams();
  const history = useHistory();
  const alert = useContext(AlertContext);

  const [loading, setLoading] = useState(false);
  const [imageUploadloading, setImageUploadLoading] = useState(false);
  const [isFileMaxSize, setIsFileMaxSize] = useState(false);
  const [file, setFile] = useState();

  const { registerUser, currentUser, registerWWWUser } = useAuth();

  useEffect(() => {
    if (currentUser.user_id) {
      history.push("/");
      // console.log("no hay usuario");
    }
  }, [currentUser, link]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const currentUrl = window.location.href;
      if (currentUrl.includes("www")) {
        const fetch = await getWWWProfile(link).then((res) => {
          if (res.error === true) {
            setLoading(false);
          } else if (res.error === false && res.profile.id_user > 0) {
            history.push(`/contact-card/${link}`);
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
      } else {
        const fetch = await getProfile(link).then((res) => {
          if (res.error === true) {
            setLoading(false);
          } else if (res.error === false && res.profile.id_user > 0) {
            history.push(`/contact-card/${link}`);
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
      }
    })();
  }, [link]);

  return (
    <section className="register__wrapper">
      <div className="register__container">
        <img src={logo} alt="Güeya" className="register__logo" />
        <h3>Crear mi cuenta</h3>
        <p className="mt-2">
          Llena todos los campos a continuación para registrarte en nuestra
          plataforma:
        </p>
        <Formik
          validationSchema={schema}
          initialValues={initialValues}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setLoading(true);
            values.link = link;
            // console.log(values);
            const currentUrl = window.location.href;
            if (currentUrl.includes("www")) {
              const response = await registerWWWUser(values).then(
                async (res) => {
                  // console.log(res);
                  if (
                    res.error === true &&
                    res.message === "Username or Email already exists"
                  ) {
                    alert.error("El nombre de usuario o email ya existen.");
                    setLoading(false);
                    return;
                  } else if (
                    res.error === false &&
                    res.message ===
                      "Your registration was completed successfully"
                  ) {
                    setImageUploadLoading(true);
                    const responseAvatar = await postWWWAvatarProfile(
                      values.avatar
                    ).then(async (r) => {
                      // console.log(r, "r");
                      // values.lastname = values.lastname;
                      values.id_user = res.user_id;
                      values.imgProfileId = r;
                      values.businessName = "";
                      values.businessRole = "";
                      values.businessWebsite = "";
                      values.businessPhone = "";
                      values.businessExt = "";
                      values.businessAddress = "";
                      values.businessNeighborhood = "";
                      values.state = "";
                      values.postalCode = "";
                      values.country = "";
                      values.tikTokLink = "";
                      values.instagramLink = "";
                      values.facebookLink = "";
                      values.linkedInLink = "";
                      const response = await updateWWWProfile(values);
                      if (response.error) {
                        setImageUploadLoading(false);
                        alert.error(
                          "Hubo un error en tu solicitud. Por favor intenta de nuevo más tarde."
                        );
                        setLoading(false);
                        setImageUploadLoading(false);
                      } else {
                        // setImageUploadLoading(false);
                        // alert.success(
                        //   "Perfil Actualizado"
                        // );
                        // setUpdateLoading(false);
                        // window.scrollTo(0, 0);
                        alert.success("Registro exitoso.");
                        history.push("/log-in");
                        setLoading(false);
                        setImageUploadLoading(false);
                      }
                    });
                    return;
                  } else {
                    alert.error(
                      "Error en tu solicitud. Por favor intenta de nuevo más tarde"
                    );
                    setLoading(false);
                    return;
                  }
                }
              );
            } else {
              const response = await registerUser(values).then(async (res) => {
                if (
                  res.error === true &&
                  res.message === "Username or Email already exists"
                ) {
                  alert.error("El nombre de usuario o email ya existen.");
                  return;
                } else if (
                  res.error === false &&
                  res.message === "Your registration was completed successfully"
                ) {
                  setImageUploadLoading(true);
                  const responseAvatar = await postAvatarProfile(
                    values.avatar
                  ).then(async (r) => {
                    if (typeof r == "number") {
                      // console.log(r, "r");
                      // values.lastname = values.lastname;
                      values.id_user = res.user_id;
                      values.imgProfileId = r;
                      values.businessName = "";
                      values.businessRole = "";
                      values.businessWebsite = "";
                      values.businessPhone = "";
                      values.businessExt = "";
                      values.businessAddress = "";
                      values.businessNeighborhood = "";
                      values.state = "";
                      values.postalCode = "";
                      values.country = "";
                      values.tikTokLink = "";
                      values.instagramLink = "";
                      values.facebookLink = "";
                      values.linkedInLink = "";
                      const response = await updateProfile(values);
                      if (response.error) {
                        setImageUploadLoading(false);
                        alert.error(
                          "Hubo un error en tu solicitud. Por favor intenta de nuevo más tarde."
                        );
                        setLoading(false);
                        // setImageUploadLoading(false);
                      } else {
                        // setImageUploadLoading(false);
                        // alert.success(
                        //   "Perfil Actualizado"
                        // );
                        // setUpdateLoading(false);
                        // window.scrollTo(0, 0);
                        alert.success("Registro exitoso.");
                        history.push("/log-in");
                        setLoading(false);
                        setImageUploadLoading(false);
                      }
                    } else {
                      alert.error(
                        "El tamaño de la imagen seleccionada supera los 2.5Mb permitidos."
                      );
                      setImageUploadLoading(false);
                      setLoading(false);
                    }
                  });
                  return;
                } else {
                  alert.error(
                    "Error en tu solicitud. Por favor intenta de nuevo más tarde"
                  );
                  setImageUploadLoading(false);
                  setLoading(false);
                  return;
                }
              });
            }
            // if (response.jwt) {
            //   alert.success(
            //     `¡Registro exitoso! Por favor, inicia sesión con tus datos.`
            //   );
            //   history.push("/login");
            //   resetForm(initialValues);
            // } else {
            //   alert.error("Error al registrar usuario.");
            //   setLoading(false);
            //   resetForm(initialValues);
            // }
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group>
                {values.avatar !== undefined && isFileMaxSize === false ? (
                  <>
                    <div
                      style={{
                        color: "#0f5132",
                        backgroundColor: "#d1e7dd",
                        borderColor: "#badbcc",
                        padding: "1rem",
                        marginBottom: "1rem",
                      }}
                    >
                      <p>
                        Tu imagen se ha cargado y se verá reflejada en tu perfil
                        al completar los campos requeridos
                      </p>
                    </div>
                    <img src={file} alt="Güeya" className="avatar" />
                  </>
                ) : (
                  <>
                    <img
                      src="https://gueya.io/assets/site/assets/images/profiledefault.jpg"
                      alt="Güeya"
                      className="avatar"
                    />
                    <input
                      type="file"
                      name="avatar"
                      onChange={async (event) => {
                        setFieldValue("avatar", event.currentTarget.files[0]);
                        setFile(
                          URL.createObjectURL(event.currentTarget.files[0])
                        );
                        const buffer =
                          await event.currentTarget.files[0].arrayBuffer();
                        // each entry of array should contain 8 bits
                        const bytes = new Uint8Array(buffer);
                        // console.log(bytes.length);
                        if (bytes.length > 2500000) {
                          setFieldValue("avatar", undefined);
                          setIsFileMaxSize(true);
                          alert.error(
                            "La imagen seleccionada supera el tamaño máximo de 2.5MB asignados. Por favor escoge una imagen de menor tamaño."
                          );
                          event.currentTarget.files[0] = undefined;
                          event.target.value = null;
                        } else {
                          setIsFileMaxSize(false);
                          values.avatar = event.currentTarget.files[0];
                          // setFieldValue("avatar", event.currentTarget.files[0]);
                        }
                      }}
                    />
                  </>
                )}
              </Form.Group>
              <Form.Group controlId="formRegisterName" className="mb-3">
                <Form.Label>Nombre:*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Escribe tu nombre"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.name && touched.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name || touched.name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formRegisterSurname" className="mb-3">
                <Form.Label>Apellidos:*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Escribe tus apellidos"
                  name="lastname"
                  value={values.lastname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.lastname && touched.lastname}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.lastname || touched.lastname}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formRegisterPhone" className="mb-3">
                <Form.Label>Número de teléfono:*</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Escribe tu número de teléfono"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.phone && touched.phone}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phone || touched.phone}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formRegisterEmail">
                <Form.Label>Email:*</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Escribe tu correo electrónico"
                  name="email"
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isInvalid={!!errors.email && touched.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email || touched.email}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formRegisterEmail">
                <Form.Label>Confirmar email:*</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Escribe, de nuevo, tu correo electrónico"
                  name="emailConfirmation"
                  value={values.emailConfirmation}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isInvalid={
                    !!errors.emailConfirmation && touched.emailConfirmation
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors.emailConfirmation || touched.emailConfirmation}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formRegisterName" className="mb-3">
                <Form.Label>Usuario:*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Escribe tu nombre de usuario"
                  name="username"
                  value={values.username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.username && touched.username}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.username || touched.username}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formRegisterPassword">
                <Form.Label>Contraseña:*</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Escribe una contraseña"
                  name="password"
                  value={values.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isInvalid={!!errors.password && touched.password}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password || touched.password}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formRegisterPassword">
                <Form.Label>Confirmar contraseña:*</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Escribe una contraseña"
                  name="passwordConfirmation"
                  value={values.passwordConfirmation}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isInvalid={
                    !!errors.passwordConfirmation &&
                    touched.passwordConfirmation
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors.passwordConfirmation || touched.passwordConfirmation}
                </Form.Control.Feedback>
              </Form.Group>
              {/* <Form.Group className="mb-3">
                <Form.Check type="checkbox">
                  <Form.Check.Input
                    type="checkbox"
                    isValid={false}
                    name="terms"
                    onChange={handleChange}
                    isInvalid={!!errors.terms && touched.terms}
                    feedback={errors.terms}
                    // feedbackType="invalid"
                  />
                  <Form.Check.Label>
                    Acepta las <Link to="/policy">políticas de servicio</Link>
                  </Form.Check.Label>
                  <Form.Control.Feedback type="invalid">
                    {errors.terms}
                  </Form.Control.Feedback>
                </Form.Check>
              </Form.Group> */}
              <button type="submit" disabled={isFileMaxSize}>
                {loading || imageUploadloading ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Cargando...</span>
                  </Spinner>
                ) : isFileMaxSize ? (
                  "Selecciona una imagen de menor tamaño para crear una cuenta"
                ) : (
                  "Registrarme ahora"
                )}
                {/* Registrarme ahora */}
              </button>
              <p>
                ¿Ya tienes cuenta? <Link to="/log-in">Inicia sesión aquí</Link>
              </p>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
}

export default Register;
