import { useEffect, useState, useContext } from "react";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";

// Custom styles
import "./CustomNav.styles.scss";

// Context
import { useAuth } from "../../contexts/AuthContext";
import AlertContext from "../../contexts/AlertContext";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

// Custom components
import { HashLink } from "react-router-hash-link";

// Assets
import logo from "../../assets/img/logo-gueya.png";
import logoWhite from "../../assets/img/logo-gueya--white.png";
import igLogo from "../../assets/img/ig--black.svg";
import fbLogo from "../../assets/img/fb--black.svg";
import igLogoWhite from "../../assets/img/ig--white.svg";
import fbLogoWhite from "../../assets/img/fb--white.svg";
import profileBlackIcon from "../../assets/img/profile-icon--black.svg";
import profileWhiteIcon from "../../assets/img/profile-icon--white.svg";

function CustomNav() {
  const [navbar, setNavbar] = useState(false);
  const [navbarLogo, setNavbarLogo] = useState(logo);
  const [igLogoState, setIgLogo] = useState(igLogo);
  const [fbLogoState, setFbLogo] = useState(fbLogo);
  const [profileIconState, setProfileIconState] = useState(profileBlackIcon);

  const history = useHistory();

  const { currentUser, setUser } = useAuth();
  const alert = useContext(AlertContext);

  const logout = () => {
    localStorage.removeItem("user");
    alert.success(`Saliste de tu cuenta.`);
    setUser({});
    history.push("/");
  };

  const changeBackground = () => {
    // console.log(window.scrollY);
    if (window.scrollY >= 50) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change Logo
    window.addEventListener("scroll", changeBackground);
  });

  const changeLogo = () => {
    if (window.scrollY >= 50 || is_root) {
      setNavbarLogo(logoWhite);
    } else {
      setNavbarLogo(logo);
      // setNavbarLogo(logo);
    }
  };

  useEffect(() => {
    changeLogo();
    // adding the event when scroll change Logo
    window.addEventListener("scroll", changeLogo);
  });

  const changeIgLogo = () => {
    if (window.scrollY >= 50 || is_root) {
      setIgLogo(igLogoWhite);
    } else {
      setIgLogo(igLogo);
      // setIgLogo(igLogo);
    }
  };

  useEffect(() => {
    changeIgLogo();
    // adding the event when scroll change Logo
    window.addEventListener("scroll", changeIgLogo);
  });

  const changeFbLogo = () => {
    if (window.scrollY >= 50 || is_root) {
      setFbLogo(fbLogoWhite);
    } else {
      setFbLogo(fbLogo);
    }
  };

  useEffect(() => {
    changeFbLogo();
    // adding the event when scroll change Logo
    window.addEventListener("scroll", changeFbLogo);
  });

  const changeProfileIcon = () => {
    if (window.scrollY >= 50 || is_root) {
      setProfileIconState(profileWhiteIcon);
    } else {
      setProfileIconState(profileBlackIcon);
    }
  };

  useEffect(() => {
    changeProfileIcon();
    // adding the event when scroll change Logo
    window.addEventListener("scroll", changeProfileIcon);
  });
  const location = useLocation();
  var is_root = location.pathname == "/";
  // console.log(is_root);
  return (
    <Navbar
      collapseOnSelect
      className={`custom-nav fixed-top ${
        navbar ? "custom-nav-active" : "normal"
      } ${is_root ? "custom-nav--light" : ""}`}
      expand="lg"
    >
      <Container>
        <Navbar.Brand as={NavLink} to="/">
          <img src={navbarLogo} alt="Güeya" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={NavLink} to="/">
              Inicio
            </Nav.Link>
            {/* <Nav.Link as={NavLink} to="/contact-card/3c47dbc6-8">
              Perfil individual
            </Nav.Link> */}
            <HashLink className="nav-link" smooth to="/#about">
              Conócenos
            </HashLink>
            <HashLink className="nav-link" smooth to="/#how-it-works">
              Cómo funciona
            </HashLink>
            <HashLink className="nav-link" smooth to="/#benefits">
              Beneficios
            </HashLink>
            <HashLink className="nav-link" smooth to="/#enterprise">
              Empresas
            </HashLink>
            {/* <Nav.Link href="#how-it-works">Cómo funciona</Nav.Link>
            <Nav.Link href="#benefits">Beneficios</Nav.Link>
            <Nav.Link href="#enterprise">Empresas</Nav.Link> */}
            {currentUser.user_id ? (
              <div className="d-flex align-items-center logout">
                <p className="p-2 nav-link" onClick={logout}>
                  Cerrar sesión
                </p>
                <Nav.Link as={NavLink} to="/admin/account/profile">
                  <img src={profileIconState} alt="Güeya" />
                </Nav.Link>
              </div>
            ) : (
              <Nav.Link as={NavLink} to="/log-in">
                Login
              </Nav.Link>
            )}
            {currentUser.admin ? (
              <Nav.Link as={NavLink} to="/admin/card-links" className="ms-0">
                Enlaces
              </Nav.Link>
            ) : null}
            <div className="custom-nav__brands">
              <a
                href="https://www.instagram.com/gueya.io/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={igLogoState} alt="Güeya" />
              </a>
              <a
                href="https://www.facebook.com/gueya.io"
                target="_blank"
                rel="noreferrer"
              >
                <img src={fbLogoState} alt="Güeya" />
              </a>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CustomNav;
