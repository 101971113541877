import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useRef } from "react";

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";

// Contexts
import { AuthProvider } from "./contexts/AuthContext";
import { AlertProvider } from "./contexts/AlertContext";

// Custom CSS
import "./scss/main.scss";

// Custom components
import CustomNav from "./components/CustomNav/CustomNav";
import MobileNav from "./components/MobileNav/MobileNav";
import Alert from "./components/Alert/Alert";
import Footer from "./components/Footer/Footer";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

// Hooks
import useWindowDimensions from "./hooks/useWindowDimensions";

// Pages
import Home from "./pages/Home";
import Else from "./pages/Else";
import Profile from "./pages/Profile";
import Login from "./pages/Login";
import Register from "./pages/Register";
import AccountProfile from "./pages/AccountProfile";
import CardLinks from "./pages/CardLinks";
import ForgotPassword from "./pages/ForgotPassword";
import RecoverPassword from "./pages/RecoverPassword";
import FacebookProfile from "./pages/FacebookProfile";
import FacebookRegister from "./pages/FacebookRegister";
import GoogleProfile from "./pages/GoogleProfile";
import GoogleRegister from "./pages/GoogleRegister";
import InstagramRegister from "./pages/InstagramRegister";
import TripAdvisorRegister from "./pages/TripAdvisorRegister";
import Privacy from "./pages/Privacy";

function App() {
  // const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const { height, width } = useWindowDimensions();

  return (
    <AuthProvider>
      <AlertProvider>
        <Alert />
        <Router>
          <header>{width < 991 ? <MobileNav /> : <CustomNav />}</header>
          <ScrollToTop />
          <Switch>
            <main>
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/admin/account/profile">
                <AccountProfile />
              </Route>
              <Route exact path="/log-in">
                <Login />
              </Route>
              <Route exact path="/sign-up/:link">
                <Register />
              </Route>
              <Route exact path="/facebook/sign-up/:link">
                <FacebookRegister />
              </Route>
              <Route exact path="/google/sign-up/:link">
                <GoogleRegister />
              </Route>
              <Route exact path="/instagram/sign-up/:link">
                <InstagramRegister />
              </Route>
              <Route exact path="/tripadvisor/sign-up/:link">
                <TripAdvisorRegister />
              </Route>
              <Route exact path="/admin/card-links">
                <CardLinks />
              </Route>
              <Route exact path="/contact-card/:link">
                <Profile />
              </Route>
              <Route exact path="/facebook/contact-card/:link">
                <FacebookProfile />
              </Route>
              <Route exact path="/google/contact-card/:link">
                <GoogleProfile />
              </Route>
              <Route exact path="/instagram/contact-card/:link">
                <InstagramRegister />
              </Route>
              <Route exact path="/tripadvisor/contact-card/:link">
                <TripAdvisorRegister />
              </Route>
              <Route exact path="/forgot-password">
                <ForgotPassword />
              </Route>
              <Route path="/recover-password/">
                <RecoverPassword />
              </Route>
              <Route path="/aviso-de-privacidad">
                <Privacy />
              </Route>
              {/* <Route exact path="/else">
                <Else />
              </Route> */}
            </main>
          </Switch>
          <Footer />
        </Router>
      </AlertProvider>
    </AuthProvider>
  );
}

export default App;
