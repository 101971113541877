import React from "react";
import { Link } from "react-router-dom";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Custom styles
import "./footer.styles.scss";

// Assets
import logo from "../../assets/img/logo-gueya.png";
import waLogo from "../../assets/img/wa-icon--white.svg";

function Footer() {
  return (
    <footer className="footer">
      <a
        href="https://wa.me/+525561482193"
        className="btn-float"
        target="_blank"
      >
        <img src={waLogo} alt="Güeya" />
      </a>
      <Container>
        <Row>
          <Col xs="12" lg="2">
            <img src={logo} alt="Güeya" className="img-fluid" />
          </Col>
          <Col
            xs="12"
            lg="7"
            className="align-items-lg-center d-flex flex-column justify-content-center my-4 my-lg-0"
          >
            <p>
              Güeya, somos mexicanos y tenemos todos los derechos reservados
              2024
            </p>
            <Link to="/aviso-de-privacidad">Aviso de privacidad</Link>
          </Col>
          <Col
            xs="12"
            lg="3"
            className="d-flex flex-column align-items-center justify-content-center"
          >
            <span>Designed by IF.DSGN.FRM</span>
            <span>
              Developed by{" "}
              <a
                href="https://quintech.mx/"
                target="_blank"
                rel="noopener noreferrer"
              >
                QuinTech
              </a>
            </span>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
