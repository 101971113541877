import { useEffect, useContext, useState } from "react";
import { useParams } from "react-router";
import { Link, useHistory } from "react-router-dom";

// Bootstrap components
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

// API
import {
  postAvatarProfile,
  postWWWAvatarProfile,
  updateProfile,
  updateWWWProfile,
  getWWWProfile,
  getProfile,
} from "../api/auth";
import { getGoogleProfile, getWWWGoogleProfile } from "../api/social";

// Context
import AlertContext from "../contexts/AlertContext";
import { useAuth } from "../contexts/AuthContext";

// Custom styles
import "../scss/pages/register.styles.scss";
import "../scss/pages/social.styles.scss";

// Assets
import logo from "../assets/img/logo-gueya.png";

// Formik for form validation
import { Formik } from "formik";
import * as yup from "yup";
import AutoComplete from "../components/Autocomplete/Autocomplete";

const initialValues = {
  name: "",
  lastname: "",
  phone: "",
  email: "",
  emailConfirmation: "",
  username: "",
  password: "",
  passwordConfirmation: "",
};

const schema = yup.object().shape({
  businessName: yup.string().required("Este campo es requerido"),
});

function GoogleRegister() {
  let { link } = useParams();
  const history = useHistory();
  const alert = useContext(AlertContext);

  const [loading, setLoading] = useState(false);
  const [profileExistsLoading, setProfileExistsLoading] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  const [file, setFile] = useState();

  const { registerUser, currentUser, registerWWWUser } = useAuth();

  useEffect(() => {
    if (currentUser.user_id) {
      history.push("/");
      // console.log("no hay usuario");
    }
  }, [currentUser, link]);

  useEffect(() => {
    (async () => {
      setProfileExistsLoading(true);
      const currentUrl = window.location.href;
      if (currentUrl.includes("www")) {
        const fetch = await getWWWGoogleProfile(JSON.stringify(link)).then(
          (res) => {
            if (res.error === true) {
              setProfileExistsLoading(false);
            } else if (res.error === false) {
              window.location.replace(
                `https://search.google.com/local/writereview?placeid=${res.profile.google_profile}`
              );
              // window.alert("Si existe");
              setIsProfile(true);
              // console.log(res.profile);
              setProfileExistsLoading(false);
            } else {
              setProfileExistsLoading(false);
            }
          }
        );
      } else {
        const fetch = await getGoogleProfile(JSON.stringify(link)).then(
          (res) => {
            if (res.error === true) {
              setProfileExistsLoading(false);
            } else if (res.error === false) {
              window.location.replace(
                `https://search.google.com/local/writereview?placeid=${res.profile.google_profile}`
              );
              // window.alert("Si existe");
              setIsProfile(true);
              // console.log(res.profile);
              setProfileExistsLoading(false);
            } else {
              setProfileExistsLoading(false);
            }
          }
        );
      }
    })();
  }, [link]);

  return (
    <section className="register__wrapper register__wrapper--reviews">
      <div className="register__container">
        {profileExistsLoading || isProfile ? (
          <div className="container text-center">
            <p>Cargando información...</p>
          </div>
        ) : (
          <>
            <img src={logo} alt="Güeya" className="register__logo" />
            <h3>Configuración de cuenta</h3>
            <p className="mt-2">
              Proporciona el nombre de tu negocio en Google:
            </p>
            <AutoComplete
              loading={loading}
              setLoading={setLoading}
              link={link}
              alert={alert}
            />
          </>
          // <p>holi</p>
        )}
      </div>
    </section>
  );
}

export default GoogleRegister;
