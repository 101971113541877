import { useRef, useEffect, useState } from "react";
import "./autocomplete.styles.scss";

// Bootstrap components
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";

// Formik for form validation
import { Formik } from "formik";
import * as yup from "yup";

// API
import { postTripAdvisorProfile } from "../../api/social";

const initialValues = {
  tripAdvisorBusiness: "",
};

const schema = yup.object().shape({
  tripAdvisorBusiness: yup.string().required("Este campo es requerido"),
});

const AutoCompleteTripAdvisor = ({ loading, setLoading, link, alert }) => {
  const inputRef = useRef();
  const [predictions, setPredictions] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState("");
  const [placeId, setPlaceId] = useState("");
  let timeout;

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener("input", handleInput);
    }

    return () => {
      // Check if the element still exists before removing the event listener
      if (inputRef.current) {
        inputRef.current.removeEventListener("input", handleInput);
      }
    };
  }, []);

  // useEffect(() => {
  //   // Simulate fetching data from an API with a 2-second delay
  //   setTimeout(() => {
  //     // This is where you would typically fetch data from an API
  //     // For simulation, we're directly setting the response data
  //     setPredictions([
  //       {
  //         location_id: "1674691",
  //         name: "Hotel Amira Istanbul",
  //         address_obj: {
  //           street1: "Kucuk Ayasofya Mah. Mustafa Pasa Sok. No: 43",
  //           street2: "",
  //           city: "Estambul",
  //           country: "Turquía",
  //           postalcode: "34122",
  //           address_string:
  //             "Kucuk Ayasofya Mah. Mustafa Pasa Sok. No: 43, Estambul 34122 Turquía",
  //         },
  //       },
  //       {
  //         location_id: "10724403",
  //         name: "Falcon Tours",
  //         address_obj: {
  //           street2: "",
  //           city: "Doha",
  //           country: "Catar",
  //           address_string: "Doha Catar",
  //         },
  //       },
  //       {
  //         location_id: "11457860",
  //         name: "Excursión privada al Taj Mahal y Agra en coche desde Delhi",
  //         address_obj: {
  //           city: "Nueva Delhi",
  //           state: "Territorio Capital Nacional de Delhi",
  //           country: "India",
  //           address_string: "Nueva Delhi India",
  //         },
  //       },
  //       {
  //         location_id: "2089597",
  //         name: "Secrets Huatulco Resort & Spa",
  //         address_obj: {
  //           street1: "Sección Hotelera Bahía de Conejos Lote 8",
  //           street2: "",
  //           city: "Huatulco",
  //           state: "Oaxaca",
  //           country: "México",
  //           postalcode: "70989",
  //           address_string:
  //             "Sección Hotelera Bahía de Conejos Lote 8, Huatulco 70989 México",
  //         },
  //       },
  //       {
  //         location_id: "6460282",
  //         name: "Amir AdvenTours Bacalar",
  //         address_obj: {
  //           street1: "5th Avenue and 46 Street",
  //           street2: "Entrance by balneario",
  //           city: "Bacalar",
  //           state: "Quintana Roo",
  //           country: "México",
  //           postalcode: "77930",
  //           address_string:
  //             "5th Avenue and 46 Street Entrance by balneario, Bacalar 77930 México",
  //         },
  //       },
  //       {
  //         location_id: "11483404",
  //         name: "Recorrido turístico en barco a la laguna Bacalar con barra libre",
  //         address_obj: {
  //           city: "Bacalar",
  //           state: "Quintana Roo",
  //           country: "México",
  //           address_string: "Bacalar México",
  //         },
  //       },
  //       {
  //         location_id: "13001444",
  //         name: "Excursión privada al Taj Mahal al amanecer desde Delhi en coche - Todo incluido",
  //         address_obj: {
  //           city: "Nueva Delhi",
  //           state: "Territorio Capital Nacional de Delhi",
  //           country: "India",
  //           address_string: "Nueva Delhi India",
  //         },
  //       },
  //       {
  //         location_id: "11462557",
  //         name: "Excursión de día completo al Taj Mahal y Agra en tren Express desde Delhi",
  //         address_obj: {
  //           city: "Nueva Delhi",
  //           state: "Territorio Capital Nacional de Delhi",
  //           country: "India",
  //           address_string: "Nueva Delhi India",
  //         },
  //       },
  //       {
  //         location_id: "13166288",
  //         name: "Excursión de un día al Taj Mahal desde Delhi en tren",
  //         address_obj: {
  //           city: "Nueva Delhi",
  //           state: "Territorio Capital Nacional de Delhi",
  //           country: "India",
  //           address_string: "Nueva Delhi India",
  //         },
  //       },
  //       {
  //         location_id: "15573992",
  //         name: "Excursión de medio día con safari por el desierto, recorrido en camello, sandboard y visita al mar interior",
  //         address_obj: {
  //           city: "Doha",
  //           country: "Catar",
  //           address_string: "Doha Catar",
  //         },
  //       },
  //     ]);
  //   }, 2000); // 2000 milliseconds delay
  // }, []);

  const handleInput = async () => {
    clearTimeout(timeout);

    // if (inputRef.current.value !== "") {
    //   console.log("sugye entrando");
    timeout = setTimeout(async () => {
      const inputValue = inputRef.current.value;
      if (inputValue !== "") {
        try {
          const response = await fetch(
            `https://api.content.tripadvisor.com/api/v1/location/search?key=${process.env.REACT_APP_TRIP_ADVISOR_API}&searchQuery=${inputValue}&language=es`,
            {
              method: "GET",
              headers: { accept: "application/json" },
              // referrer: "gueya.io",
              // referrerPolicy: "strict-origin",
              // mode: "no-cors",
            }
          );

          const data = await response.json();

          // Update predictions state with data from the API
          setPredictions(data.data);
          console.log(data.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
      //  API call
    }, 300); // Adjust the delay (in milliseconds) as needed
    // } else {
    //   console.log("else");
    //   setSelectedBusiness("");
    //   inputRef.current.value = "";
    //   setPredictions([]);
    // }
  };

  const handleSelectPrediction = (prediction, setFieldValue, alert) => {
    setSelectedBusiness(
      `${prediction.name} ${prediction.address_obj.address_string}`
    );
    setPlaceId(prediction.location_id);
    setFieldValue(
      "tripAdvisorBusiness",
      `${prediction.name} ${prediction.address_obj.address_string}`,
      true
    );

    // Optionally, clear predictions if needed
    setPredictions([]);
  };

  // Render predictions
  const renderPredictions = (setFieldValue) => {
    return predictions.map((prediction) => (
      <li
        key={prediction.location_id}
        onClick={() => handleSelectPrediction(prediction, setFieldValue)}
      >
        {prediction.name}
      </li>
    ));
  };

  // const options = {
  //   method: "GET",
  //   headers: { accept: "application/json" },
  //   mode: "no-cors",
  //   referrer: "www.gueya.io",
  // };

  // fetch(
  //   `https://api.content.tripadvisor.com/api/v1/location/search?language=en&key=D9A3D5571FD54260B3FB20F7AB91AA00searchQuery=${inputValue}`,
  //   options
  // )
  //   .then((response) => response.json())
  //   .then((response) => console.log(response))
  //   .catch((err) => console.error(err));

  return (
    // <div>
    //   <label>Enter address:</label>
    //   <input ref={inputRef} />
    //   {console.log(selectedBusiness)}
    //   <ul>
    //     {predictions.map((prediction, index) => (
    //       <li key={index}>{prediction.name}</li>
    //     ))}
    //   </ul>
    // </div>
    <>
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        // innerRef={(f) => (inputRef.current = f)}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setLoading(true);
          // values.tripAdvisorBusiness = selectedBusiness;
          values.link = link;
          values.tripAdvisorBusiness = placeId;
          const currentUrl = window.location.href;
          const response = await postTripAdvisorProfile(values).then(
            async (res) => {
              // console.log(res.error);
              // -- VALIDATION IF IT EXISTS --
              if (res.error === true) {
                alert.error("Hubo un error al guardar tus datos");
                setLoading(false);
                return;
              } else {
                setLoading(false);
                alert.success("Registro exitoso");
                window.location.replace(
                  `https://www.tripadvisor.com/UserReviewEdit-g1202648-d${values.tripAdvisorBusiness}`
                );
                // console.log("success");
              }
            }
          );
          // console.log(values);
          setLoading(false);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          setFieldValue,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            {/* {console.log(selectedBusiness)} */}
            <Form.Group controlId="formRegisterName" className="mb-1">
              <Form.Label>Nombre de tu negocio en Trip Advisor:*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Escribe el nombre"
                name="tripAdvisorBusiness"
                value={
                  // Object.keys(selectedBusiness).length === 0
                  selectedBusiness === ""
                    ? values.tripAdvisorBusiness
                    : selectedBusiness
                  // inputRef.current.value
                  // values.tripAdvisorBusiness
                }
                onChange={(e) => {
                  handleChange(e);
                  setSelectedBusiness(e.target.value);
                }}
                onBlur={handleBlur}
                // innerRef={(f) => (inputRef.current = f)}
                isInvalid={
                  !!errors.tripAdvisorBusiness && touched.tripAdvisorBusiness
                }
                ref={inputRef}
                disabled={placeId !== ""}
              />
              <Form.Control.Feedback type="invalid">
                {errors.tripAdvisorBusiness || touched.tripAdvisorBusiness}
              </Form.Control.Feedback>
            </Form.Group>
            <p
              onClick={() => {
                setSelectedBusiness("");
                setPlaceId("");
                inputRef.current.value = "";
                values.tripAdvisorBusiness = "";
              }}
              className={`register__wrapper--reviews__reset ${
                selectedBusiness !== "" ? "d-block" : "d-none"
              }`}
            >
              Limpiar campo
            </p>
            <button
              type="submit"
              disabled={loading || placeId === ""}
              // Object.keys(selectedBusiness).length === 0
            >
              {loading ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Cargando...</span>
                </Spinner>
              ) : placeId === "" ? (
                "Ingresa tu información"
              ) : (
                "Guardar información"
              )}
            </button>
            {/* <p>
          ¿Ya tienes cuenta? <Link to="/log-in">Inicia sesión aquí</Link>
        </p> */}
            <div
              className={`${
                predictions.length > 0
                  ? "register__wrapper--reviews__trip-results"
                  : ""
              }`}
            >
              <ul>{renderPredictions(setFieldValue)}</ul>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AutoCompleteTripAdvisor;
