import { useContext } from "react";
import { Link, useHistory } from "react-router-dom";

// Custom styles
import "./MobileNav.styles.scss";

// Context
import { useAuth } from "../../contexts/AuthContext";
import AlertContext from "../../contexts/AlertContext";

// Custom components
import { HashLink } from "react-router-hash-link";

// Assets
import igLogo from "../../assets/img/ig--black.svg";
import fbLogo from "../../assets/img/fb--black.svg";
import profileBlackIcon from "../../assets/img/profile-icon--black.svg";

function MobileNav() {
  const showNav = () => {
    document.getElementsByClassName("mobile-nav")[0].classList.toggle("active");
  };
  const hideNav = () => {
    document.getElementsByClassName("mobile-nav")[0].classList.toggle("active");
  };

  const history = useHistory();

  const { currentUser, setUser } = useAuth();
  const alert = useContext(AlertContext);

  const logout = () => {
    hideNav();
    localStorage.removeItem("user");
    alert.success(`Saliste de tu cuenta.`);
    setUser({});
    history.push("/");
  };
  return (
    <div className="mobile-nav">
      <div className="ham-btn" onClick={showNav}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className="links">
        <div className="link">
          <Link to="/" onClick={hideNav}>
            Inicio
          </Link>
        </div>
        <div className="link">
          {/* <Link to="#how-it-works">Conócenos</Link> */}
          <HashLink smooth to="/#about" onClick={hideNav}>
            Conócenos
          </HashLink>
        </div>
        {/* <Link to="/contact-card/3c47dbc6-8">Perfil individual</Link> */}
        <div className="link">
          <HashLink smooth to="/#how-it-works" onClick={hideNav}>
            Cómo funciona
          </HashLink>
        </div>
        <div className="link">
          <HashLink smooth to="/#benefits" onClick={hideNav}>
            Beneficios
          </HashLink>
        </div>
        <div className="link">
          <HashLink smooth to="/#enterprise" onClick={hideNav}>
            Empresas
          </HashLink>
        </div>
        <div className="link">
          {currentUser.user_id ? (
            <div className="d-flex flex-column logout-mobile">
              <Link
                to="/admin/account/profile"
                className="m-0 mb-2"
                onClick={hideNav}
              >
                <p>Mi perfil</p>
              </Link>
              <p className="p-2" onClick={logout}>
                Cerrar sesión
              </p>
            </div>
          ) : (
            <Link to="/log-in" onClick={hideNav}>
              Login
            </Link>
          )}
        </div>
        {currentUser.admin ? (
          <div className="link">
            <Link to="/admin/card-links" className="" onClick={hideNav}>
              Enlaces
            </Link>
          </div>
        ) : null}
        <div className="mobile-nav__social d-flex justify-content-center">
          <a
            href="https://www.instagram.com/gueya.io/"
            target="_blank"
            rel="noreferrer"
            className="me-3"
            onClick={hideNav}
          >
            <img src={igLogo} alt="Güeya" />
          </a>
          <a
            href="https://www.facebook.com/gueya.io"
            target="_blank"
            rel="noreferrer"
            onClick={hideNav}
          >
            <img src={fbLogo} alt="Güeya" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default MobileNav;
