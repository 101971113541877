import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

// API
import { getProfile, getWWWProfile } from "../api/auth";

// Custom styles
import "../scss/pages/profile.styles.scss";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Assets
import logo from "../assets/img/logo-mod.png";

function GoogleProfile() {
  let { link } = useParams();

  const [loading, setLoading] = useState(false);
  const [currentProfile, setCurrentProfile] = useState({});

  // console.log("link", link);
  // console.log("currentProfile", currentProfile);

  const history = useHistory();

  // console.log(Object.keys(currentProfile).length);
  useEffect(() => {
    (async () => {
      setLoading(true);
      const currentUrl = window.location.href;
      if (currentUrl.includes("www")) {
        const fetch = await getWWWProfile(link).then((res) => {
          if (res.error === true) {
            history.push(`/google/sign-up/${link}`);
          } else if (res.error === false) {
            setCurrentProfile(res.profile);
            setLoading(false);
          } else {
            setCurrentProfile({});
            setLoading(false);
          }
          // console.log(res);
        });
      } else {
        const fetch = await getProfile(link).then((res) => {
          console.log("sin wwww");
          if (res.error === true) {
            history.push(`/google/sign-up/${link}`);
          } else if (res.error === false) {
            setCurrentProfile(res.profile);
            setLoading(false);
          } else {
            setCurrentProfile({});
            setLoading(false);
          }
          // console.log(res);
        });
      }
    })();
  }, [link]);

  return (
    <section className="profile__wrapper">
      <div className="profile__container">
        <div className="profile__container__header">
          <img src={logo} alt="Güeya" />
        </div>
        {loading && Object.keys(currentProfile).length === 0 ? (
          <Container>
            <p>Cargando datos...</p>
          </Container>
        ) : (
          <>
            <div className="profile__container__details">
              <Container fluid className="p-0">
                <Row>
                  <h2>hello this google page</h2>
                </Row>
              </Container>
            </div>
          </>
        )}
      </div>
    </section>
  );
}

export default GoogleProfile;
