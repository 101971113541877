import { useState, useContext, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";

// Custom styles
import "../scss/pages/login.styles.scss";

// Bootstrap components
import Form from "react-bootstrap/Form";

// Context
import AlertContext from "../contexts/AlertContext";
import { useAuth } from "../contexts/AuthContext";

// API
// import { login } from "../api/auth";

// Formik for form validation
import { Formik } from "formik";
import * as yup from "yup";

const schema = yup.object().shape({
  username: yup.string().required("Este campo es requerido"),
  password: yup.string().required("Este campo es requerido"),
});

function Login() {
  const [loading, setLoading] = useState(false);
  const { loginUser, wwwLoginUser, setUser, currentUser } = useAuth();

  const history = useHistory();

  const alert = useContext(AlertContext);

  useEffect(() => {
    if (currentUser.user_id) {
      history.push("/admin/account/profile");
      // console.log("no hay usuario");
    }
  }, [currentUser]);

  return (
    <section className="login__wrapper">
      <div className="login__container">
        <h1>Iniciar sesión</h1>
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            setLoading(true);
            // setTimeout(async () => {
            // }, 4000);
            const currentUrl = window.location.href;
            if (currentUrl.includes("www")) {
              const response = await wwwLoginUser({
                username: values.username,
                password: values.password,
              });
              if (response.error) {
                setLoading(false);
                if (response.res?.type === "unknow_user") {
                  alert.error("El nombre de usuario no se encontró.");
                  return;
                } else if (response.res?.type === "password") {
                  alert.error("Contraseña incorrecta.");
                  return;
                } else {
                  alert.error(
                    "Hubo un error en tu solicitud. Por favor intenta de nuevo más tarde."
                  );
                  return;
                }
              }
              if (response.url) {
                response.url.replaceAll("/", "");
                let newString = response.url.replaceAll(
                  "https://gueya.io/",
                  ""
                );
                // console.log(newString);
                if (newString.includes("admin/account/profile")) {
                  alert.success("Inicio de sesión exitoso.");
                  history.push("/admin/account/profile");
                } else {
                  alert.success("Inicio de sesión exitoso.");
                  setUser((prevState) => ({
                    ...prevState,
                    admin: true,
                  }));
                  localStorage.setItem(
                    "user",
                    JSON.stringify({ user_id: response.user_id, admin: true })
                  );
                  history.push("/admin/card-links");
                }
                setLoading(false);
              }
            } else {
              const response = await loginUser({
                username: values.username,
                password: values.password,
              });
              if (response.error) {
                setLoading(false);
                if (response.res?.type === "unknow_user") {
                  alert.error("El nombre de usuario no se encontró.");
                  return;
                } else if (response.res?.type === "password") {
                  alert.error("Contraseña incorrecta.");
                  return;
                } else {
                  alert.error(
                    "Hubo un error en tu solicitud. Por favor intenta de nuevo más tarde."
                  );
                  return;
                }
              }
              if (response.url) {
                response.url.replaceAll("/", "");
                let newString = response.url.replaceAll(
                  "https://gueya.io/",
                  ""
                );
                // console.log(newString);
                if (newString.includes("admin/account/profile")) {
                  alert.success("Inicio de sesión exitoso.");
                  history.push("/admin/account/profile");
                } else {
                  alert.success("Inicio de sesión exitoso.");
                  setUser((prevState) => ({
                    ...prevState,
                    admin: true,
                  }));
                  localStorage.setItem(
                    "user",
                    JSON.stringify({ user_id: response.user_id, admin: true })
                  );
                  history.push("/admin/card-links");
                }
                setLoading(false);
              }
            }

            // if (!response) {
            //   alert.error("Error al comunicarse con el servidor.");
            //   setLoading(false);
            //   return;
            // }
            // if (response.status === 200) {
            //   history.push("/");
            //   setLoading(false);
            // } else {
            //   // console.log("error aqui bro");
            //   response.json().then((json) => {
            //     if (json.error === "INVALID_PASSWORD") {
            //       alert.error("Contraseña incorrecta.");
            //     } else if (json.error === "EMAIL_NOT_FOUND") {
            //       alert.error("Email no encontrado.");
            //     } else if (
            //       json.error ===
            //       "TOO_MANY_ATTEMPTS_TRY_LATER : Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later."
            //     ) {
            //       alert.error(
            //         "Excediste los intentos permitidos. Espera un momento o restaura tu contraseña."
            //       );
            //     } else {
            //       alert.error(json.error);
            //     }
            //   });
            //   setLoading(false);
            // }
            // alert("Submitted!");
            // resetForm({
            //   username: "",
            //   password: "",
            // });
          }}
          initialValues={{
            username: "",
            password: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Nombre de usuario:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Escribe aquí tu nombre de usuario"
                  name="username"
                  value={values.username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.username && !!errors.username}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.username || touched.username}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Contraseña:</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Contraseña"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.password && !!errors.password}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password || touched.password}
                </Form.Control.Feedback>
              </Form.Group>
              <Link to="/forgot-password" className="d-block text-center">
                ¿Olvidaste tu contraseña?
              </Link>
              <button type="submit" disabled={loading}>
                {loading ? "Iniciando sesión..." : "Iniciar sesión"}
              </button>
              {/* <p>
                ¿No tienes cuenta? <Link to="/sign">Regístrate aquí</Link>
              </p> */}
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
}

export default Login;
