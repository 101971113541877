import { useRef, useEffect, useState } from "react";
import "./autocomplete.styles.scss";

// Bootstrap components
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

// Formik for form validation
import { Formik } from "formik";
import * as yup from "yup";

// API
import { postGoogleProfile } from "../../api/social";

const initialValues = {
  googleBusiness: "",
};

const schema = yup.object().shape({
  googleBusiness: yup.string().required("Este campo es requerido"),
});

const AutoComplete = ({ loading, setLoading, link, alert }) => {
  const autoCompleteRef = useRef();
  const [selectedBusiness, setSelectedBusiness] = useState("");
  const [placeId, setPlaceId] = useState("");
  const inputRef = useRef();
  const options = {
    componentRestrictions: { country: "mx" },
  };
  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );
    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();
      console.log({ place });
      setSelectedBusiness(
        `${place.name} ${place.vicinity} `
        // `${place.name} ${place.address_components[1].long_name} ${place.address_components[2].long_name} ${place.address_components[3].long_name} ${place.address_components[4].long_name}`
      );
      setPlaceId(place.place_id);
    });
  }, [link, autoCompleteRef.current]);
  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setLoading(true);
        values.link = link;
        values.googlePlaceId = placeId;
        // console.log(values);
        const currentUrl = window.location.href;
        // if (currentUrl.includes("www")) {
        //   const response = await registerWWWUser(values).then(async (res) => {
        //     // console.log(res);
        //     if (
        //       res.error === true &&
        //       res.message === "Username or Email already exists"
        //     ) {
        //       alert.error("El nombre de usuario o email ya existen.");
        //       setLoading(false);
        //       return;
        //     } else if (
        //       res.error === false &&
        //       res.message === "Your registration was completed successfully"
        //     ) {
        //       setImageUploadLoading(true);
        //       const responseAvatar = await postWWWAvatarProfile(
        //         values.avatar
        //       ).then(async (r) => {
        //         // console.log(r, "r");
        //         // values.lastname = values.lastname;
        //         values.id_user = res.user_id;
        //         values.imgProfileId = r;
        //         values.businessName = "";
        //         values.businessRole = "";
        //         values.businessWebsite = "";
        //         values.businessPhone = "";
        //         values.businessExt = "";
        //         values.businessAddress = "";
        //         values.businessNeighborhood = "";
        //         values.state = "";
        //         values.postalCode = "";
        //         values.country = "";
        //         values.tikTokLink = "";
        //         values.instagramLink = "";
        //         values.facebookLink = "";
        //         values.linkedInLink = "";
        //         const response = await updateWWWProfile(values);
        //         if (response.error) {
        //           setImageUploadLoading(false);
        //           alert.error(
        //             "Hubo un error en tu solicitud. Por favor intenta de nuevo más tarde."
        //           );
        //           setLoading(false);
        //           setImageUploadLoading(false);
        //         } else {
        //           // setImageUploadLoading(false);
        //           // alert.success(
        //           //   "Perfil Actualizado"
        //           // );
        //           // setUpdateLoading(false);
        //           // window.scrollTo(0, 0);
        //           alert.success("Registro exitoso.");
        //           history.push("/log-in");
        //           setLoading(false);
        //           setImageUploadLoading(false);
        //         }
        //       });
        //       return;
        //     } else {
        //       alert.error(
        //         "Error en tu solicitud. Por favor intenta de nuevo más tarde"
        //       );
        //       setLoading(false);
        //       return;
        //     }
        //   });
        // } else {
        const response = await postGoogleProfile(values).then(async (res) => {
          console.log(res.error);
          // -- VALIDATION IF IT EXISTS --
          if (res.error === true) {
            alert.error("Hubo un error al guardar tus datos");
            setLoading(false);
            return;
          } else {
            setLoading(false);
            alert.success("Registro exitoso");
            window.location.replace(
              `https://search.google.com/local/writereview?placeid=${values.googlePlaceId}`
            );
            // console.log("success");
          }
          // -- ENDS --
          // if (
          //   res.error === false &&
          //   res.message === "Your registration was completed successfully"
          // ) {
          //   setImageUploadLoading(true);
          //   const responseAvatar = await postAvatarProfile(
          //     values.avatar
          //   ).then(async (r) => {
          //     if (typeof r == "number") {
          //       // console.log(r, "r");
          //       // values.lastname = values.lastname;
          //       values.id_user = res.user_id;
          //       values.imgProfileId = r;
          //       values.businessName = "";
          //       values.businessRole = "";
          //       values.businessWebsite = "";
          //       values.businessPhone = "";
          //       values.businessExt = "";
          //       values.businessAddress = "";
          //       values.businessNeighborhood = "";
          //       values.state = "";
          //       values.postalCode = "";
          //       values.country = "";
          //       values.tikTokLink = "";
          //       values.instagramLink = "";
          //       values.facebookLink = "";
          //       values.linkedInLink = "";
          //       const response = await updateProfile(values);
          //       if (response.error) {
          //         setImageUploadLoading(false);
          //         alert.error(
          //           "Hubo un error en tu solicitud. Por favor intenta de nuevo más tarde."
          //         );
          //         setLoading(false);
          //         // setImageUploadLoading(false);
          //       } else {
          //         // setImageUploadLoading(false);
          //         // alert.success(
          //         //   "Perfil Actualizado"
          //         // );
          //         // setUpdateLoading(false);
          //         // window.scrollTo(0, 0);
          //         alert.success("Registro exitoso.");
          //         history.push("/log-in");
          //         setLoading(false);
          //         setImageUploadLoading(false);
          //       }
          //     } else {
          //       alert.error(
          //         "El tamaño de la imagen seleccionada supera los 2.5Mb permitidos."
          //       );
          //       setImageUploadLoading(false);
          //       setLoading(false);
          //     }
          //   });
          //   return;
          // } else {
          //   alert.error(
          //     "Error en tu solicitud. Por favor intenta de nuevo más tarde"
          //   );
          //   setImageUploadLoading(false);
          //   setLoading(false);
          //   return;
          // }
        });
        console.log(response);
        // }
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          {console.log(selectedBusiness)}
          <Form.Group controlId="formRegisterName" className="mb-3">
            <Form.Label>Nombre de tu negocio en Google:*</Form.Label>
            <Form.Control
              type="text"
              placeholder="Escribe tu nombre"
              name="googleBusiness"
              value={
                // Object.keys(selectedBusiness).length === 0
                selectedBusiness === ""
                  ? values.googleBusiness
                  : selectedBusiness
              }
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={!!errors.googleBusiness && touched.googleBusiness}
              ref={inputRef}
            />
            <Form.Control.Feedback type="invalid">
              {errors.googleBusiness || touched.googleBusiness}
            </Form.Control.Feedback>
          </Form.Group>
          <button
            type="submit"
            disabled={loading || selectedBusiness === ""}
            // Object.keys(selectedBusiness).length === 0
          >
            {loading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Cargando...</span>
              </Spinner>
            ) : selectedBusiness === "" ? (
              "Ingresa tu información"
            ) : (
              "Guardar información"
            )}
          </button>
          {/* <p>
          ¿Ya tienes cuenta? <Link to="/log-in">Inicia sesión aquí</Link>
        </p> */}
        </Form>
      )}
    </Formik>
    // <div>
    //   <label>enter address :</label>
    //   <input ref={inputRef} />
    // </div>
  );
};
export default AutoComplete;
